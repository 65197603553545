import { Label } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { IQuoteFilters } from ".";
import { clone } from 'lodash'

interface CheckboxFilterProps
{
    options: {[key: string]: string | number;}
    selectedOptions: string[];
    setOption: (filter: Partial<IQuoteFilters>) => void;
    title: string;
    propertyName: string;
}


const CheckboxFilter = (props: CheckboxFilterProps) => 
{
    const { options, selectedOptions, setOption, title, propertyName } = props;

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const value = e.target.value;
        const isChecked: boolean = e.target.checked;
        const valueIndex: number = selectedOptions.indexOf(value);
        const newSelectedOptions = clone(selectedOptions);

        if(isChecked && valueIndex === -1)
        {
            //updateFilter({[propertyName]: [...selectedOptions, value]} )
            setOption({[propertyName]: {value: [...selectedOptions, value]}} )
        }

        if(!isChecked && valueIndex !== -1)
        {
            newSelectedOptions.splice(valueIndex, 1);
            setOption({[propertyName]: {value: [...newSelectedOptions]}})
        }
    }

    const generateCheckboxes = (): ReactNode => {

        const checkBoxes: ReactNode[] = [];

        for(const key in options) 
        {
            checkBoxes.push(
                <FormControlLabel 
                    sx={{width: "100%"}}
                    control={ <Checkbox onChange={handleCheckboxChange} value={options[key]} checked={(selectedOptions.indexOf(options[key]) !== -1)} />} 
                    label={key} 
                />
            )
        }

        return checkBoxes;
    }


    return (
        <>
            <Typography
                variant="body1"
                aria-label="allowed excess weight"
                sx={{ fontWeight: "bold" }}
            >
                {title}
            </Typography>
            {generateCheckboxes()}
        </>
    )

}

export default CheckboxFilter;