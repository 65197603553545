import React, { useState } from "react";
import { Box, Typography, Grid, Card, CardHeader, CardContent, CardActions, Button, Slide, Snackbar, Alert, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Mark } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";

import WasteContainer from "../../../types/wasteContainer";
import ContainerDetails from "./containerDetails";
import WasteType from "../../../types/wasteType";
import { RootState } from "../../../store/store";
import { KeyValuePair } from "../../../types/wasteDetails";
import { addWasteDetails } from "../../../store/quoteSlice";
import ContainerQuantity from "./containerQuantity";
import CollectionFrequency from "./collectionFrequency";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import Utils from "../../../utils/utils";

export interface AddQuoteItemProps {
    wasteTypes: WasteType[],
    preSelectedWasteType: KeyValuePair | null,
    onQuoteItemAdded: any,
    onCancelAddQuoteItem: any
}

export interface AddQuoteItemState {
    wasteType: KeyValuePair | null,
    containerType: KeyValuePair | null,
    quantity: number,
    collectionFrequency: KeyValuePair | null,
    daysPerWeek: number | null
};

const addQuoteItemInitialState:AddQuoteItemState = {
    wasteType: null,
    containerType: null,
    quantity: 0,
    collectionFrequency: null,
    daysPerWeek: null
}

const canHaveWasteType = (wc: WasteContainer, wt: string) => {

    var canHaveWasteType = false;

    //Loop through waste types available for each container and check if specified waste type is available
    wc.wasteTypes.forEach((wcWasteType: string) => {

        if(wcWasteType == wt) {
            canHaveWasteType = true;
            return;
        }
    });

    return canHaveWasteType;
}

const wasteTypeContainers = (wc: WasteContainer[], wt: string) => {

    var filteredContainers: WasteContainer[] = [];

    //Loop through waste types available for each container and check if specified waste type is available
    wc.forEach((container: WasteContainer) => {

        if(canHaveWasteType(container, wt)) {
            filteredContainers.push(container);
        }
    });

    return filteredContainers;
}

const getAvailableQuantities = (wc: WasteContainer[], containerType: string, largeScreen: boolean) => {

    var quantities:Mark[] = [];

    wc.forEach((item) => {

        if(item.apiValue == containerType) {

            item.quantities.forEach((q,index) => {
                quantities.push({
                    value: Number(q),
                    label: largeScreen ? q : (index + 1) % 2 ? "" : q
                })
            });
            
            return;
        }

    })

    return quantities;
}

const getMaxQty = (wc: WasteContainer[], containerType: string) => {

    var maxQty = 0;

    wc.forEach((item) => {

        if(item.apiValue == containerType) {

            item.quantities.forEach((q) => {
                
                var convNumber = Number(q);

                //If current item is larger than the stored max, set.
                if(convNumber > maxQty) {
                    maxQty = convNumber;
                }

            });
            
            return;
        }

    })

    return maxQty;
}

const AddQuoteItem = (props: AddQuoteItemProps) => {

    React.useEffect(() => {
        
        //Pre select waste type if parent has specified to do so
        if(props.preSelectedWasteType != null && newQuoteItem.wasteType == null) {
            newQuoteItem.wasteType = props.preSelectedWasteType;
        }
    })

    //Global state
    const availableContainerOptions = useSelector((state:RootState) => state.quote.availableContainerOptions); 
    const addedQuoteItems = useSelector((state:RootState) => state.quote.wasteDetails);

    const dispatch = useDispatch();
    
    //Component state
    const [newQuoteItem, setNewQuoteItem] = useState<AddQuoteItemState>(addQuoteItemInitialState);
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const handleSelectWasteType = (swt: KeyValuePair) => {        
        setNewQuoteItem(newQuoteItem => ({
            ...newQuoteItem,
            ...{
                wasteType: swt,
                containerType: null,
                quantity: 0,
                collectionFrequency: null
            }
        }));

        //Move to container section
        setTimeout(() => {
            Utils.scrollToSection("containerSection", 88);
        }, 100);
    }

    const handleContainerSelected = (cont: KeyValuePair) => {
        
        //Update component state
        setNewQuoteItem(newQuoteItem => ({
            ...newQuoteItem,
            ...{
                containerType: cont,
                quantity: 0
            }
        }));

        //Move to quantity section
        Utils.scrollToSection("quantitySection", 0);
    }

    const handleQuantityChanged = (value: number) => {

        //Update component state
        setNewQuoteItem(newQuoteItem => ({
            ...newQuoteItem,
            ...{
                quantity: value
            }
        }));

        //Move to collection frequency section
        Utils.scrollToSection("collectionFrequencySection", 0);
    }

    const handleCollectionFrequencyChanged = (value: KeyValuePair) => {

        //Update component state
        setNewQuoteItem(newQuoteItem => ({
            ...newQuoteItem,
            ...{
                collectionFrequency: value
            }
        }));

        //If collection frequency isn't weekly reset days per week
        if (value.key != "CF_WK") {
            handleDaysPerWeekChanged(null);
        }
    }

    const handleDaysPerWeekChanged = (value: number | null) => {

        //Update component state
        setNewQuoteItem(newQuoteItem => ({
            ...newQuoteItem,
            ...{
                daysPerWeek: value
            }
        }))
    }

    const handleAddToQuoteClick = () => {

        var errors = [];

        //Check container size has been selected
        if(newQuoteItem.containerType == null || newQuoteItem.containerType.key == "") {
            errors.push("Container size");
        }

        //Check quantity has been selected
        if (newQuoteItem.quantity == null || newQuoteItem.quantity == 0) {
            errors.push("Quantity");
        }

        //Check collection frequency has been selected
        if (newQuoteItem.collectionFrequency == null || newQuoteItem.collectionFrequency.key == "") {
            errors.push("Collection frequency");
        }

        //If weekly collection check the number of days per week has been selected
        if (newQuoteItem.collectionFrequency?.key == "CF_WK" && (newQuoteItem.daysPerWeek == null || newQuoteItem.daysPerWeek == 0))
        {
            errors.push("Days per week");
        }

        //Check if any errors and show message
        if(errors.length > 0) 
        {
            setErrors(errors);
            setShowError(true);
        }

        else 
        {
            //Update waste details in global state
            dispatch(addWasteDetails({
                wasteType: newQuoteItem.wasteType as KeyValuePair,
                containerType: newQuoteItem.containerType as KeyValuePair,
                qty: newQuoteItem.quantity,
                collectionFrequency: newQuoteItem.collectionFrequency,
                daysPerWeek: newQuoteItem.daysPerWeek
            }));

            //Clear component state
            setNewQuoteItem(addQuoteItemInitialState);

            //Parent callback
            props.onQuoteItemAdded();
        }
    }

    const handleCancelAddToQuoteClick = () => {

        //Clear component state
        setNewQuoteItem(addQuoteItemInitialState);

        //Parent callback
        props.onQuoteItemAdded();
    }

    const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setShowError(false);
    };

    return (
        <Box>
            <Slide direction="right" in={props.wasteTypes != null && props.wasteTypes.length > 0}>
                <Box mb={4} hidden={props.wasteTypes == null || props.wasteTypes.length == 0}>
                    <Typography variant="body2" color="secondary">Waste Type</Typography>
                    <Typography variant="body1" mt={1} mb={2}>Please select the type of waste that will need collecting.</Typography>
                    <Grid container spacing={2}>
                    {props.wasteTypes?.map((wt, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card sx={{boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"}}>
                  <CardHeader
                    title={ <Typography sx={{ color: 'primary.dark', textAlign:'center', fontWeight: 600}}>{ wt.name }</Typography> }
                    style={{ height: 90, alignItems: "flex-start" }}
                    titleTypographyProps={{variant:'h6', color:'primary.dark', textAlign:'center' }}
                    subheader={wt.description}
                    subheaderTypographyProps={{align:'center'}}
                  />
                  <CardContent sx={{height: upSmallScreen ? 170 : 165}}>
                      <Box sx={{alignItems:'center', textAlign:'center', display:'flex'}}>
                        {
                            wt.image != undefined && wt.image != null &&
                            <Box sx={{margin:'auto'}}>
                              <GatsbyImage alt="Container Type" image={getImage(wt.image) as IGatsbyImageData} style={{ width: upSmallScreen ? 140 : 120 }} />
                            </Box>
                        }
                      </Box>
                  </CardContent>
                  <CardActions sx={{backgroundColor:'#f2f2f2', py:2 }}>
                    <Button
                      color="primary"
                      variant={
                        newQuoteItem.wasteType?.key == wt.apiValue
                          ? "contained"
                          : "outlined"
                      }
                      fullWidth
                      onClick={() =>
                        handleSelectWasteType({
                          key: wt.apiValue,
                          value: wt.name,
                        })
                      }
                    >
                      Select
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
                    </Grid>
                    <div id="containerSection"></div>
                </Box>
            </Slide>
            <Slide direction="right" in={newQuoteItem.wasteType != null}>
                <Box mb={2} hidden={newQuoteItem.wasteType == null}>
                    <Box mb={5}>
                        <Typography variant="body2" mt={2} color="secondary">Container Size</Typography>
                        <Typography variant="body1" mt={1} mb={2}>Please select the size/type of container that will need collecting.</Typography> 
                        <Grid container spacing={2}>
                            { 
                                wasteTypeContainers(availableContainerOptions, newQuoteItem.wasteType?.key as string)?.map((cont, index) => (
                                    <Grid item xs={12} sm={4} lg={3} key={index}>
                                        <ContainerDetails
                                            containerDetails={cont}
                                            selectedContainerSize={newQuoteItem.containerType?.key as string}
                                            onSelected={(args:KeyValuePair) => handleContainerSelected(args)}
                                            />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <div id="quantitySection"></div>
                    </Box>
                    {
                        newQuoteItem.containerType?.key != null &&
                        <Box mb={1}>
                            <Typography variant="body2" mt={2} color="secondary">Quantity</Typography>
                            <Typography variant="body1" mt={1} mb={2}>Please select how many of these containers you would like?</Typography>
                            <ContainerQuantity
                                quantities={getAvailableQuantities(availableContainerOptions, newQuoteItem.containerType?.key ?? "", upSmallScreen)}
                                maxQty={getMaxQty(availableContainerOptions, newQuoteItem.containerType?.key ?? "")}
                                selectedQuantity={newQuoteItem.quantity}
                                onChange={(value:number) => handleQuantityChanged(value)} />
                            <div id="collectionFrequencySection"></div>
                        </Box>
                    }
                    <Box>
                        <Typography variant="body2" color="secondary">Collection Frequency</Typography>
                        <Typography variant="body1" mt={1} mb={2}>Please select how frequently you would like the waste collecting?</Typography>
                        <CollectionFrequency
                            selectedCollectionFrequency={newQuoteItem.collectionFrequency?.key ?? ""}
                            selectedDaysPerWeek={newQuoteItem.daysPerWeek}
                            onChange={(value:KeyValuePair) => handleCollectionFrequencyChanged(value)}
                            onDaysPerWeekChange={(value:number) => handleDaysPerWeekChanged(value)} />
                    </Box>
                </Box>
            </Slide>
            <Box mt={3}>
                <Grid container spacing={2}>
                    {
                        addedQuoteItems.length > 0 &&
                        <Grid item xs={6} md={3}>
                            <Button 
                                variant="contained" 
                                size="small" 
                                color="error"
                                fullWidth
                                onClick={() => handleCancelAddToQuoteClick()}>
                                Cancel
                            </Button>
                        </Grid>
                    }
                    {
                        newQuoteItem.wasteType != null &&
                        <Grid item xs={6} md={3}>
                            <Button 
                                id="addToQuote"
                                variant="contained" 
                                size="small" 
                                fullWidth
                                onClick={() => handleAddToQuoteClick() }>
                                Add To Quote
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Snackbar open={showError} autoHideDuration={18000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Please ensure you complete the following information before adding to the quote.
                    <List dense={true}>
                        { errors.map((err, index) => (
                            <ListItem key={index}>
                                <ListItemText>&#8226; {err}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default AddQuoteItem