import React, { useState } from "react";
import { acceptQuote, nextStep } from "../../store/quoteSlice";
import {
  Typography,
  Grid,
  Box,
  Button,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

const GridItemWrap = styled(Grid)(
  ({ firstitem, lastitem, columntitle, ismonthlycost, isactive }) => {
    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    return {
      height: "60px",
      border: `1px solid lightgrey`,
      borderLeft: upSmallScreen && !firstitem && "0px",
      borderRadius: firstitem ? "2px 0 0 2px" : lastitem ? "0 2px 2px 0" : "0",
      display: "grid",
      alignContent: "center",
      justifyItems: "center",
      justifyContent: columntitle && "center",
      textAlign: "center",
      background:
        columntitle || ismonthlycost
          ? theme?.palette.primary.light
          : isactive
          ? theme?.palette.primary.dark
          : "white",
      color:
        columntitle || ismonthlycost
          ? theme?.palette.primary.dark
          : isactive
          ? theme?.palette.secondary.contrastText
          : theme?.palette.primary.dark,
    };
  }
);

const ViewDetailsWrapper = styled(Box)(
  ({ viewDetails, upSmallScreen, upMediumScreen, theme, firstitem }) => {
    return {
      position: "relative",
      top: upSmallScreen ? "-16px" : "0",
      marginTop: upSmallScreen && firstitem ? "16px" : "0",
      overflow: "hidden",
      backgroundColor: true
        ? `${theme?.palette?.primary?.main}1a`
        : "rgba(255, 255, 255, 0.5)",
      width: "100%",
      maxHeight: viewDetails ? "500px" : "0px",
      padding: viewDetails ? "16px" : "0px",
      paddingBottom: "0px",
      opacity: viewDetails ? "1" : "0",
      borderRadius: "2px",
      transition: "all 0.3s ease-in-out",
      display: "grid",
      gridTemplateColumns: upMediumScreen ? "1fr 1fr" : "1fr",
      gridGap: "5px",
    };
  }
);

const ViewDetailsItem = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "40% 60%",
  gridGap: "5px",
  alignItems: "center"
}));

const SuccessButton = styled(Button)(({ upSmallScreen }) => ({
  aspectRatio: upSmallScreen ? "0.6 / 0.2" : "100%",
  minWidth: upSmallScreen ? "64px" : "40px",
  width: "92%",
  height: 0,
  margin: "0 10px 10px",
  fontSize: "12px",
  whiteSpace: "nowrap",
  textAlign: "center",
  gridRow: "1 / 2",
  borderRadius: upSmallScreen ? "30px" : "5px",
  padding: upSmallScreen ? "20px 40px" : "20px 0",
  lineHeight: 1,
  boxShadow: "0px 3px 5px 0px rgb(0 0 0 / 50%)",
  zIndex: 10,
}));

const ToggleButton = styled(Button)(
  ({ upSmallScreen, viewDetails, theme }) => ({
    aspectRatio: upSmallScreen ? "0.6 / 0.2" : "100%",
    background: viewDetails
      ? theme?.palette?.secondary?.light
      : theme?.palette?.secondary?.main,
    minWidth: upSmallScreen ? "64px" : "40px",
    width: "100%",
    height: 0,
    fontSize: "15px",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderRadius: upSmallScreen ? "30px" : "5px",
    padding: upSmallScreen ? "20px 40px" : "20px 0",
    lineHeight: 1,
    boxShadow: "0px 3px 5px 0px rgb(0 0 0 / 50%)",
    zIndex: 10,
  })
);

const ViewDetails = ({
  viewDetails,
  handleViewDetailsToggle,
  quote
}) => {
  const theme = useTheme();
  const upMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const wasteDetails = useSelector((state) => state.quote.wasteDetails);
  const dispatch = useDispatch();

  const formatQuote = (wasteDetails, generatedQuote) => {
    const formattedQuote = [];

    wasteDetails.forEach((item) => {
      const prices = [];

      //Add corresponding prices to list
      if (generatedQuote != null && generatedQuote?.prices != null) {
        generatedQuote?.prices.forEach((price) => {
          if (
            price.wasteType == item.wasteType.key &&
            price.containerType == item.containerType.key
          ) {
            prices.push(price);
          }
        });

        //Set waste details
        formattedQuote.push({
          wasteType: item.wasteType.value,
          containerType: item.containerType.value,
          collectionFrequency: item.collectionFrequency?.value ?? "",
          qty: item.qty ?? 0,
          daysPerWeek: item?.daysPerWeek,
          prices: prices.sort(function (a, b) {
            if (a.amount < b.amount) {
              return -1;
            }

            return 0;
          }),
        });
      }
    });

    return formattedQuote;
  };

  const handleCreateLeadClicked = () => {
    //Move to next step
    dispatch(nextStep());
    dispatch(acceptQuote( {quote: quote} ));
    //Reset window position
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const viewDetailsStyles = {
    heading: {
      fontSize: "14px",
      lineHeight: "18px"
    },
    value: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 600
    }
  }

  return (
    <>
      {formatQuote(wasteDetails, quote).map((singleQuote, i) => {
        return (
          <ViewDetailsWrapper
            viewDetails={viewDetails}
            upSmallScreen={upSmallScreen}
            upMediumScreen={upMediumScreen}
            theme={theme}
            firstitem={i === 0}
            key={singleQuote?.wasteType + i}
          >
            <Box
              mb={1}
              sx={{ fontWeight: "bold", gridColumn: "1/-1" }}
            >{`Quote item ${i + 1}`}</Box>
             <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Supplier Name
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                {singleQuote?.prices?.[0]?.supplier.name}
              </Typography>
            </ViewDetailsItem>
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Waste Type
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                {singleQuote?.wasteType}
              </Typography>
            </ViewDetailsItem>
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Container Type
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                {singleQuote?.containerType}
              </Typography>
            </ViewDetailsItem>
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Unit Cost
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                £{singleQuote?.prices?.[0]?.amount?.toFixed(2)} <Typography display="inline" variant="body1" sx={{fontSize: "12px"}}>(excl VAT)</Typography>
              </Typography>
            </ViewDetailsItem>
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Collection Frequency
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                {singleQuote?.collectionFrequency}
              </Typography>
            </ViewDetailsItem>
            {singleQuote?.daysPerWeek && (
              <ViewDetailsItem>
                <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                  Days Per Week
                </Typography>
                <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                  {singleQuote?.daysPerWeek}
                </Typography>
              </ViewDetailsItem>
            )}
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Cheapest Title">
                Qty
              </Typography>
              <Typography sx={viewDetailsStyles.value} variant="body1" aria-label="Cheapest Title">
                {singleQuote?.qty}
              </Typography>
            </ViewDetailsItem>
            <ViewDetailsItem>
              <Typography sx={viewDetailsStyles.heading} variant="body1" aria-label="Excess Weight">
                Excess Weight Charge
              </Typography>
              <Typography
                variant="body1"
                aria-label="Excess Weight Charge"
                sx={viewDetailsStyles.value}
              >
                {quote?.prices?.[i]?.excessWeightCharge > 0 ? `${quote?.prices?.[i]?.weightRestrictions}kg limit & £${quote?.prices?.[i]?.excessWeightCharge?.toFixed(2)} per kg` : "None"}
              </Typography>
            </ViewDetailsItem>
            {quote?.prices?.length !== i - 1 && (
              <Divider sx={{ gridColumn: "1/-1", marginTop: "16px" }} />
            )}
          </ViewDetailsWrapper>
        );
      })}
      {viewDetails && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            justifyItems: "center",
            direction: "rtl",
            background: `${theme?.palette?.primary?.main}1a`,
            position: "relative",
            top: upSmallScreen ? "-16px" : 0,
            padding: upSmallScreen ? "15px 0" : "10px 8px",
            paddingBottom: upSmallScreen ? "6px" : "0"
          }}
        >
          <SuccessButton
            variant="contained"
            size="small"
            color="success"
            upSmallScreen={upSmallScreen}
            sx={{
              gridColumn: upSmallScreen ? "1 / 2" : "1 / 4",
              fontSize: "15px"
            }}
            onClick={() => handleCreateLeadClicked()}
          >
            Select
          </SuccessButton>
        </div>
      )}
      {!upSmallScreen && (
        <GridItemWrap
          item
          xs={upSmallScreen ? 2 : 12}
          sx={{
            padding: "5px",
            background: `${theme?.palette?.primary?.main}1a`,
            border: "none",
            padding: "0 14px",
          }}
        >
          <ToggleButton
            variant="contained"
            size={upSmallScreen ? "medium" : "small"}
            color="secondary"
            upSmallScreen={upSmallScreen}
            viewDetails={viewDetails}
            theme={theme}
            onClick={() => handleViewDetailsToggle()}
          >
            {viewDetails ? "Hide Details" : "View Details"}
          </ToggleButton>
        </GridItemWrap>
      )}
    </>
  );
};

export default ViewDetails;
