import { QuoteRequest } from "../../types/quote";
import apiClient from "../apiClient";

const generateQuote = (data:QuoteRequest, accessToken:string) => {
    
    return apiClient.post(
        "/quote/generate", 
        JSON.stringify(data), 
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
}

export default generateQuote