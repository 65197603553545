import React, {useState} from "react";
import { Container, Typography, Card, CardHeader, CardContent, Grid, Box, Hidden, Avatar, Divider, Stack, Button, useMediaQuery, Checkbox, Tab, Tabs } from "@mui/material";
import { useTheme, styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridRowsProp, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ScaleIcon from '@mui/icons-material/Scale';
import AddCardIcon from '@mui/icons-material/AddCard';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useDispatch } from "react-redux";
import { Link as GatsbyLink } from "gatsby";

import { resetState } from "../../store/quoteSlice";
import { Price } from "../../api/endpoints/quote";
import ComparedQuotes from "./ComparedQuotes";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export interface QuotesProps {
    quoteReference: string,
    quotes: Quote[],
    onAmendQuote: any,
    onAcceptPrice: any,
    onCreateLead: any
}

export interface Quote {
    wasteType: string,
    containerType: string,
    collectionFrequency: string,
    qty: number,
    prices: Price[] | null
}

enum QuoteTabs {
    priceView = "priceView",
    quoteView = "quoteView"
}

const Link = styled(GatsbyLink) (() =>
  'text-decoration: none'
)

const Quotes = (props: QuotesProps) => {

    //COMPONENT STATE

    const [activeTab, setActiveTab] = useState<QuoteTabs>(QuoteTabs.quoteView);

    const columns: GridColDef[] = [
        { 
            field: 'supplier', 
            headerName: 'Supplier', 
            flex: 1.3,
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    <Avatar sx={{width:26, height:26, mr:1 }}>
                        <DeleteSweepIcon sx={{fontSize:18}}></DeleteSweepIcon>
                    </Avatar>
                    {params.colDef.headerName}
                </>
    
            ),
            renderCell: (params: GridRenderCellParams) => (
                <><Typography variant="body1">{params.value.name}</Typography></>
            )
        },
        { 
            field: 'amount', 
            headerName: 'Price',
            flex: 1, 
            align:'center', 
            headerAlign: 'center',
            sortable:true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    <Avatar sx={{width:26, height:26, mr:1 }}>
                        <CurrencyPoundIcon sx={{fontSize:18}}></CurrencyPoundIcon>
                    </Avatar>
                    {params.colDef.headerName}
                </>
    
            ),
            renderCell: (params:GridRenderCellParams) => (
                <><Typography variant="body1">£{params.value.toFixed(2)}</Typography></>
            )
        },
        { 
            field: 'weightRestrictions', 
            headerName: 'Weight Restrictions (kg)', 
            flex: 1, 
            align:'center', 
            headerAlign: 'center',
            sortable:true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    <Avatar sx={{width:26, height:26, mr:1 }}>
                        <ScaleIcon sx={{fontSize:18}}></ScaleIcon>
                    </Avatar>
                    {params.colDef.headerName}
                </>
    
            ),
            renderCell: (params:GridRenderCellParams) => (
                <><Typography variant="body1">{params.value}</Typography></>
            )
        },
        { 
            field: 'excessWeightCharge', 
            headerName: 'Excess Weight Charge (per kg)', 
            flex: 1, 
            align: 'center', 
            headerAlign: 'center',
            sortable:true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                    <Avatar sx={{width:26, height:26, mr:1 }}>
                        <AddCardIcon sx={{fontSize:18}}></AddCardIcon>
                    </Avatar>
                    {params.colDef.headerName}
                </>
    
            ),
            renderCell: (params:GridRenderCellParams) => (
                <>
                    <Typography variant="body1">{params.value != null ? "£" + params.value.toFixed(2) : ""}</Typography>
                </>
            )
        },
        {
            field: 'accepted',
            headerName: 'a',
            headerAlign: 'center',
            flex: 0.1,
            align: 'center',
            renderHeader: (params:GridColumnHeaderParams) => (
                <>
                    <Box sx={{color:'primary.main'}}>
                        {params.colDef.headerName}
                    </Box>
                </>
            ),
            renderCell: (params:GridRenderCellParams) => (
                <>
               
                </>
            )
        }
    ]

    //GLOBAL STATE

    const dispatch = useDispatch();

    const theme = useTheme();

    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    //HANDLERS
    
    const handleCreateLeadClick = () => {
        props.onCreateLead();
    }

    const handleAmendQuoteClick = () => {
        props.onAmendQuote();
    }

     const handleNewQuoteClick = () => {
         dispatch(resetState());
     }

     const handlePriceSelected = (wasteType:string, containerType:string, id:number) => {

        //Notify parent list of prices have changed
        props.onAcceptPrice(wasteType, containerType, id);
     }

    return (
        <Container>
            <TabContext value={activeTab}>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList 
                        TabIndicatorProps={{style: {borderLeft: "none", borderRight: "none", borderTop: "none", borderRadius: "0", backgroundColor: "unset" }}} 
                        onChange={(e, value) => setActiveTab(value)}>
                        <Tab label="Quotes" sx={{ fontSize: 17, zIndex: "0 !important" }} value={QuoteTabs.quoteView} />
                        <Tab label="Supplier Prices" sx={{ fontSize: 17, zIndex: "0 !important" }} value={QuoteTabs.priceView} />
                    </TabList>
                </Box>
                <TabPanel value={QuoteTabs.quoteView}>
                    <ComparedQuotes />
                </TabPanel>
                <TabPanel value={QuoteTabs.priceView}>
                    <Hidden lgDown>
                        {   props.quotes.map((quote:Quote, index:number) => (
                                <Box height="100%" width="100%" mt={1} mb={2} key={index}>
                                    <Card>
                                        <CardHeader
                                            title={<Typography variant="body2">Quote item { (index + 1) }</Typography> }
                                            subheader={
                                                <Stack direction="row" spacing={1} divider={ <Divider orientation="vertical" flexItem /> }>
                                                    <Box>
                                                        <b>Waste Type:</b> {quote.wasteType}
                                                    </Box>
                                                    <Box>
                                                        <b>Container Type:</b> {quote.containerType}
                                                    </Box>
                                                    <Box>
                                                        <b>Collection Frequency:</b> {quote.collectionFrequency}
                                                    </Box>
                                                    <Box>
                                                        <b>Quantity:</b> {quote.qty}
                                                    </Box>
                                                </Stack>
                                            } />
                                        <CardContent>
                                            <Box>
                                                <DataGrid 
                                                    autoHeight
                                                    columns={columns}
                                                    sx={{...componentStyles.hideSelectAllGrid}}
                                                    rows={quote.prices as GridRowsProp<Price>}
                                                    initialState={{
                                                        sorting: {
                                                            sortModel: [{ field: 'amount', sort: 'asc' }]
                                                        }
                                                    }}
                                                    components={{
                                                        NoRowsOverlay: () => (
                                                            <Box sx={{width:"100%", height:"100%", textAlign:"center", pt:5, color:'primary.dark'}}>
                                                                <Typography variant="h5">
                                                                    Unfortunately we couldn't find any matching suppliers.
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    }}
                                                    disableSelectionOnClick={true}
                                                    />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Divider></Divider>
                                </Box>
                            ))
                        }
                    </Hidden>
                    <Hidden lgUp>
                        {
                            props.quotes.map((quote:Quote, index:number) => (
                                    
                                <Box height="100%" width="100%" mt={1} mb={2} key={index}>
                                    <Card>
                                        <CardHeader
                                            title={"Quote item " + (index + 1)}
                                            subheader={
                                                <Stack direction="column" divider={ <Divider orientation="vertical" flexItem /> } mt={1}>
                                                    <Box>
                                                        <b>Waste Type:</b> {quote.wasteType}
                                                    </Box>
                                                    <Box>
                                                        <b>Container Type:</b> {quote.containerType}
                                                    </Box>
                                                    <Box>
                                                        <b>Collection Frequency:</b> {quote.collectionFrequency}
                                                    </Box>
                                                    <Box>
                                                        <b>Quantity:</b> {quote.qty}
                                                    </Box>
                                                </Stack>
                                            }
                                                />
                                        <CardContent>
                                            <Grid container spacing={1}>
                                            {
                                                quote.prices?.map((price:Price, priceIndex:number) => (
                                                    <Grid item xs={12} key={priceIndex}>
                                                        <Card>
                                                            <CardHeader
                                                                title={"£" + price.amount.toFixed(2)}
                                                                subheader={price.supplier.name}
                                                                subheaderTypographyProps={{ color:'primary.light' }}
                                                                sx={{ color:'primary.dark', borderBottom:'2px solid #f2f2f2' }}
                                                            />
                                                            <CardContent>
                                                                <Typography variant="body1" fontSize={13}><b>Weight Restrictions:</b> {price.weightRestrictions}kg</Typography>
                                                                <Typography variant="body1" fontSize={13}><b>Excess Weight Charge:</b> {price.excessWeightCharge != null ? "£" + price.excessWeightCharge.toFixed(2) : ""} per kg</Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Divider></Divider>
                                </Box>
                            ))
                        }
                    </Hidden>
                </TabPanel>
            </TabContext>
            {
                (props.quoteReference != null && props.quoteReference != "") &&
                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Button 
                                variant="contained"
                                size="large"
                                sx={{width: upSmallScreen ? "55%" : "100%", backgroundColor:"secondary.main" }} 
                                onClick={() => handleAmendQuoteClick()}>
                                New Quote
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                (props.quoteReference == null || props.quoteReference == "") &&
                <Box mt={2} textAlign="center">
                    <Card>
                        <CardContent>
                            <Typography variant="h6" my={3}>
                                Unfortunately we couldn't find any matching suppliers.
                            </Typography>
                            <Link to="/quote">
                                <Button 
                                    variant="contained" 
                                    size="medium" 
                                    onClick={() => handleNewQuoteClick()}>
                                    Generate New Quote
                                </Button>
                            </Link>
                        </CardContent>
                    </Card>
                </Box>
            }
        </Container>
    );

}

const componentStyles = {
    hideSelectAllGrid: {
        "& .MuiDataGrid-columnHeaderCheckbox": {
            height:100
        },
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root": {
            display: "none"
        }
    }
}

export default Quotes;