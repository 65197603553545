import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  Card,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Sentry from "@sentry/gatsby";

import Address from "../../types/address";
import findAddress, { findAddressResponse } from "../../api/endpoints/address";
import { useAuth0 } from "@auth0/auth0-react";

enum ComponentStatus {
  Search,
  AddressFound,
  ListPostcodeAddresses,
}

interface FindAddressState {
  postcode: string;
  buildingNumberOrName: string;
  status: ComponentStatus;
  address: Address;
  postcodeAddresses: Address[];
  selectedPostcodeAddress?: number;
}

const initialState: FindAddressState = {
  status: ComponentStatus.Search,
  postcode: "",
  buildingNumberOrName: "",
  address: new Address(),
  postcodeAddresses: [],
};

const FindAddress = (props: any) => {
  //STATE
  const [state, setState] = useState<FindAddressState>(initialState);
  const [showError, setShowError] = useState(false);
  const [searching, setSearching] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const theme = useTheme();
  const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const componentStyles = {
    formLabel: {
      paddingBottom: "5px",
    },
    findAddress: {
      marginTop: downSmallScreen ? "16px" : "5px",
      marginLeft: downSmallScreen ? "0" : "18px",
      "&.MuiLoadingButton-loading": {
        backgroundColor: "#5569ff",
      },
      "& .MuiLoadingButton-loadingIndicator": {
        color: "white",
        fontWeight: "bold",
      },
    },
  };

  //HANDLERS

  const handleBuildingNumberOrNameChanged = (e: any) => {
    setState((state) => ({
      ...state,
      ...{
        buildingNumberOrName: e.target.value,
      },
    }));
  };

  const handlePostcodeChanged = (e: any) => {
    setState((state) => ({
      ...state,
      ...{
        postcode: e.target.value,
      },
    }));
  };

  const handleFindAddressClick = async () => {
    setSearching(true);

    //Retrieve access token to make API call
    const token = await getAccessTokenSilently();

    //Lookup address
    findAddress(
      state.postcode,
      state.buildingNumberOrName,
      user?.email ?? "",
      user?.org_id,
      token
    )
      .then((res) => {
        var responseBody: findAddressResponse = res.data;

        //Check request was successful
        if (responseBody.code === "OK") {
          //Check if an exact match
          if (responseBody.exactMatch) {
            //Update state
            setState((state) => ({
              ...state,
              ...{
                status: ComponentStatus.AddressFound,
                address: responseBody.addresses[0],
              },
            }));

            addressSelected(responseBody.addresses[0]);
          }

          //List addresses at postcode
          else {
            //Update state
            setState((state) => ({
              ...state,
              ...{
                status: ComponentStatus.ListPostcodeAddresses,
                postcodeAddresses: responseBody.addresses,
              },
            }));
          }
        }

        setSearching(false);
      })
      .catch((err) => {
        Sentry.configureScope(function (scope) {
          scope.setTag("email", user?.email);
        });
        Sentry.captureException(err);

        setShowError(true);
        setSearching(false);
      });
  };

  const handleSelectAddress = (e: SelectChangeEvent<number>) => {
    //Update state
    setState((state) => ({
      ...state,
      ...{
        status: ComponentStatus.AddressFound,
        address: state.postcodeAddresses[e.target.value as number],
        postcodeAddresses: [],
      },
    }));

    addressSelected(state.postcodeAddresses[e.target.value as number]);
  };

  const handleEditAddressClick = () => {
    //Update state
    setState(initialState);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const addressSelected = (address: Address) => {
    if (props.addressSelected !== undefined) {
      props.addressSelected(address);
    }
  };

  return (
    <Container disableGutters={true}>
      <Container
        disableGutters={true}
        sx={{
          display: state.status !== ComponentStatus.Search ? "none" : "inherit",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              aria-label="House number or name"
              sx={{ ...componentStyles.formLabel }}
            >
              Premises name or number
            </Typography>
            <TextField
              variant="outlined"
              value={state.buildingNumberOrName}
              onChange={handleBuildingNumberOrNameChanged}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              aria-label="Postcode"
              sx={{ ...componentStyles.formLabel }}
            >
              Postcode
            </Typography>
            <TextField
              variant="outlined"
              label="e.g. M22 4SY"
              value={state.postcode}
              onChange={handlePostcodeChanged}
            ></TextField>
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ ...componentStyles.findAddress }}
              onClick={handleFindAddressClick}
              disabled={
                state.buildingNumberOrName.length === 0 ||
                state.postcode.length === 0
              }
              loading={searching}
            >
              Find Address
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
      <Card
        sx={{ p: 2 }}
        elevation={5}
        hidden={state.status !== ComponentStatus.AddressFound}
      >
        <Button
          variant="contained"
          size="medium"
          sx={{ float: "right", color: "white" }}
          onClick={handleEditAddressClick}
        >
          Edit
        </Button>
        <Typography variant="body1">
          {state.address != null ? state.address.addressLine1 : ""}
        </Typography>
        <Typography variant="body1">
          {state.address != null ? state.address.addressLine2 : ""}
        </Typography>
        <Typography variant="body1">
          {state.address != null ? state.address.addressLine3 : ""}
        </Typography>
        <Typography variant="body1">
          {state.address != null ? state.address.postTown : ""}
        </Typography>
        <Typography variant="body1">
          {state.address != null ? state.address.postcode : ""}
        </Typography>
      </Card>
      <Container
        disableGutters={true}
        sx={{
          display:
            state.status !== ComponentStatus.ListPostcodeAddresses
              ? "none"
              : "inherit",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              aria-label="Select address"
              sx={{ ...componentStyles.formLabel }}
            >
              Sorry we couldn't find an exact match for your address please
              select from the options below
            </Typography>
            {state.postcodeAddresses != null &&
              state.postcodeAddresses.length > 0 && (
                <Select
                  value={state.selectedPostcodeAddress}
                  onChange={handleSelectAddress}
                  fullWidth={true}
                  displayEmpty
                >
                  <MenuItem
                    disabled
                    value={undefined}
                    key={"select-address-option"}
                  >
                    Please select an address
                  </MenuItem>
                  {state.postcodeAddresses.map((address, index) => (
                    <MenuItem value={index} key={index}>
                      {Address.convertToString(address)}
                    </MenuItem>
                  ))}
                </Select>
              )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={showError}
        autoHideDuration={18000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          We're sorry an error has occured please try again later.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FindAddress;
