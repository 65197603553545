import React, { useState, MouseEvent } from "react";
import { Grid, Card, CardHeader, CardContent, Divider, Button, useMediaQuery, Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";

import FindAddress from "../general/findAddress";
import Address from "../../types/address";
import { setAddress, setAvailableContainerOptions, removeWasteDetails } from "../../store/quoteSlice";
import AddQuoteItem from "./addQuoteItem";
import WasteContainer from "../../types/wasteContainer";
import WasteType from "../../types/wasteType";
import PageTitle from "../page/pageTitle";
import { RootState } from "../../store/store";
import SelectedQuoteItems from "./selectedQuoteItems";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { KeyValuePair } from "../../types/wasteDetails";
import Utils from "../../utils/utils";

const getWasteTypeIndex = (wasteTypes:WasteType[], wt: string) => {

    for (var i = 0; i < wasteTypes.length; i++) {
        if(wasteTypes[i].apiValue === wt) {
            return i;
        }
    }

    return -1;
}

interface quoteDetailsQuery {
    allContentfulWasteContainer: {
        nodes:[{
            name: string,
            description: {
                description: string
            },
            apiValue: string,
            picture: {
                gatsbyImageData: IGatsbyImageData
            },
            containerCategory: {
                id: string
            }
            wasteTypes:[{
                apiValue: string,
                name: string,
                description: {
                    internal: {
                        content: string
                    }
                }
            }],
            quantities:string[]
        }]
    }
}

export interface QuoteDetailsProps {
    onGetQuote: any
}

const QuoteDetails = (props:QuoteDetailsProps) => {

    //GLOBAL STATE
    const selectedQuoteType = useSelector((state:RootState) => state.quote.quoteType);
    const selectedAddress = useSelector((state:RootState) => state.quote.address);
    const selectedWasteDetails = useSelector((state:RootState) => state.quote.wasteDetails);
    
    const dispatch = useDispatch();

    const theme = useTheme();

    //COMPONENT STATE
    const [wasteTypes, setWasteTypes]= useState<WasteType[]>([]);
    const [addQuoteItem, setAddQuoteItem] = useState(true);
    const [selectWasteType, setSelectWasteType] = useState<KeyValuePair | null>(null);

    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    //Fetch waste data from Contentful
    const data = useStaticQuery<quoteDetailsQuery>(
        graphql`
            query quoteDetailsQuery {
                allContentfulWasteContainer(sort: {fields: order, order: ASC}) {
                    nodes {
                      name
                      description {
                        description
                      }
                      apiValue
                      picture {
                        gatsbyImageData
                      }
                      containerCategory {
                        id
                      }
                      wasteTypes {
                        apiValue
                        name
                        description {
                          internal {
                            content
                          }
                        },
                        picture {
                            gatsbyImageData
                        }
                      },
                      quantities
                    }
                }
            }`
    );

    const handleAddressSelected = (address:Address) => {

        //Set selected address
        dispatch(setAddress(address))

        const filterContainers: WasteContainer[] = [];
        const filterWasteTypes: WasteType[] = [];

        //Find corresponding waste category
        data.allContentfulWasteContainer.nodes.forEach((cont) => {

            //If container is for the selected quote type add to filtered list
            if(cont.containerCategory.id == selectedQuoteType?.key) {

                //Add all containers available waste types
                cont.wasteTypes.forEach((wt:any) => {

                    //Add to list if doesn't already exist
                    if(getWasteTypeIndex(filterWasteTypes, wt.apiValue) < 0) {

                        filterWasteTypes.push({
                            name: wt.name,
                            description: wt.description.internal.content,
                            apiValue: wt.apiValue,
                            image: wt.picture
                        });
                    }
                });

                //Add to available containers
                var containerWasteTypes:string[] = [];

                cont.wasteTypes.forEach((wt:any) => {
                    containerWasteTypes.push(wt.apiValue);
                });

                filterContainers.push({
                    title: cont.name,
                    description: cont.description.description,
                    apiValue: cont.apiValue,
                    image: cont.picture?.gatsbyImageData,
                    wasteTypes: containerWasteTypes,
                    quantities: cont.quantities
                });
            }
        });

        //Set available waste types
        setWasteTypes(filterWasteTypes);

        //Set available containers
        dispatch(setAvailableContainerOptions(filterContainers));

         //Move to add quote item section
        Utils.scrollToSection("addQuoteItemSection", 88);

        //Preselect waste type if only 1
        if(filterWasteTypes.length == 1) {
            setSelectWasteType({
                key: filterWasteTypes[0].apiValue,
                value: filterWasteTypes[0].name
            })
        }
    }

    const handleQuoteItemAdded = () => {
        
        //Hide add quote item
        setAddQuoteItem(false);
    }

    const handleAddQuoteItemCancelled = () => {

        //Hide add quote item
        setAddQuoteItem(false);
    }

    const handleQuoteItemRemoved = (wasteType:string, containerType:string) => {

        //Remove from global state
        dispatch(removeWasteDetails({ 
            wasteType: wasteType, 
            containerType: containerType 
        }));

        //If no quote items show add quote item
        if(selectedWasteDetails.length <= 1) {
            setAddQuoteItem(true);
        }
    }

    const handleGetQuoteClicked = (event:MouseEvent<any>) => {

        if(props.onGetQuote != undefined) {
            props.onGetQuote();
        }
    }

    return (
        <Container>
            <Box mb={2}>
                <PageTitle subHeading="Please provide the below details so we can generate a quote." />
            </Box>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Card>
                        <CardHeader 
                            title="Collection Address"
                            titleTypographyProps={{ fontSize: "16px !important" }} />
                        <Divider />
                        <CardContent>
                            <FindAddress addressSelected={ (address:Address) => {handleAddressSelected(address)} } />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card hidden={selectedAddress == null}>
                        <CardHeader 
                            title="Quote Details"
                            titleTypographyProps={{ fontSize: "16px !important" }} />
                        <Divider />
                        <div id="addQuoteItemSection"></div>
                        <CardContent>
                            <Box>
                                <Box hidden={!addQuoteItem}>
                                    <AddQuoteItem 
                                        wasteTypes={wasteTypes}
                                        preSelectedWasteType={selectWasteType}
                                        onQuoteItemAdded={() => handleQuoteItemAdded()}
                                        onCancelAddQuoteItem={() => handleAddQuoteItemCancelled() } />
                                </Box>
                                <Box hidden={addQuoteItem}>
                                    <Typography variant="body1" mb={2}>Details of the waste, containers and service that need collecting.</Typography>
                                    <SelectedQuoteItems 
                                        onAddQuoteItemClick={() => setAddQuoteItem(true)} 
                                        onRemoveQuoteItemClick={(wasteType:string, containerType:string) => handleQuoteItemRemoved(wasteType, containerType)}/>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{margin:'auto', textAlign:'center'}}>
                    <Box hidden={selectedAddress == null || selectedWasteDetails.length == 0 || addQuoteItem } mt={2}>
                        <Button 
                            variant="contained" 
                            size="large" 
                            color="success"
                            sx={{width: upSmallScreen ? "30%" : "100%" }} 
                            onClick={handleGetQuoteClicked}>
                            Get Quote
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default QuoteDetails