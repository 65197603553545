export default class Address {

    constructor() {
        this.uprn = "";
        this.udprn = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.addressLine3 = "";
        this.postTown = "";
        this.postcode = "",
        this.postcodeOutward = "";
        this.organizationName = "";
        this.addressString = "";
    }

    uprn: string | null | undefined;
    udprn: string | null | undefined;
    addressLine1: string | null;
    addressLine2: string | null | undefined;
    addressLine3: string;
    postTown: string | null | undefined;
    postcode: string | null | undefined;
    postcodeOutward: string | null | undefined;
    organizationName: string | null | undefined;
    addressString: string;

    public static convertToString (addr:Address) {

        var addressString = '';
    
        if(addr.addressLine1 !== null && addr.addressLine1 !== '') {
            addressString = addr.addressLine1;
        }
        
        if(addr.addressLine2 !== null && addr.addressLine2 !== '') {
            addressString += (addressString.length > 0 ? ', ' : '') + addr.addressLine2;
        }
    
        if(addr.addressLine3 !== null && addr.addressLine3 !== '') {
            addressString += (addressString.length > 0 ? ', ' : '') + addr.addressLine3;
        }
    
        return addressString;
    };
};