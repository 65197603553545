import React from "react";
import { Snackbar, Alert, List, ListItem, ListItemText } from "@mui/material";

export interface FormErrorsProps {
    title: string,
    errors?: string[],
    showErrors: boolean,
    onClose: any
}

const FormErrors = (props:FormErrorsProps) => {

    //HANDLES

    const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
        
        if (reason === 'clickaway') {
            return;
        }

        props.onClose();
    };

    return (
        <Snackbar open={props.showErrors} autoHideDuration={10000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                {props.title}
                <List dense={true}>
                    { props.errors != undefined && props.errors?.map((err, index) => (
                        <ListItem key={index}>
                            <ListItemText>&#8226; {err}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Alert>
        </Snackbar>
    )
}

export default FormErrors