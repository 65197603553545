import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ViewDetails from "./ViewDetails";

const GridContainerWrap = styled(Grid)<{isFilter?: boolean}>(({ isFilter }) => ({
  marginBottom: isFilter ? "16px" : "0",
  height: isFilter ? "100px" : "100%",
  maxHeight: "100px",
}));

const GridItemWrap = styled(Grid)<{isMonthlyCost?: boolean, firstItem?: boolean, lastItem?: boolean, 
  columnTitle?: boolean, isActive?: boolean, upSmallScreen?: boolean, viewDetails?: boolean, displayType?: string}>(
  ({ firstItem, lastItem, columnTitle, isMonthlyCost, isActive, upSmallScreen, viewDetails, theme, displayType  }) => ({
    height: "60px",
    border: `1px solid lightgrey`,
    borderLeft: (upSmallScreen && !firstItem) ? "0px" : "1px solid lightgrey",
    borderRadius: firstItem ? "2px 0 0 2px" : lastItem ? "0 2px 2px 0" : "0",
    display: displayType ? displayType : "grid",
    alignContent: "center",
    justifyItems: "center",
    justifyContent: (columnTitle !== undefined) ? "center" : "flex-start",
    textAlign: "center",
    background:
      columnTitle || isMonthlyCost
        ? theme?.palette.primary.light
        : isActive
        ? theme?.palette.primary.dark
        : "white",
    color:
      columnTitle || isMonthlyCost
        ? theme?.palette.primary.dark
        : isActive
        ? theme?.palette.secondary.contrastText
        : theme?.palette.primary.dark,
  })
);

const ToggleButton = styled(Button)<{upSmallScreen: boolean, viewDetails: boolean}>(
  ({ upSmallScreen, viewDetails, theme }) => ({
    aspectRatio: upSmallScreen ? "0.6 / 0.2" : "100%",
    background: viewDetails
      ? theme?.palette?.info?.light
      : theme?.palette?.info?.main,
    minWidth: upSmallScreen ? "64px" : "40px",
    width: "100%",
    height: 0,
    fontSize: "14px",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderRadius: upSmallScreen ? "30px" : "5px",
    padding: upSmallScreen ? "20px 40px" : "20px 0",
    lineHeight: 1,
    boxShadow: "0px 3px 5px 0px rgb(0 0 0 / 50%)",
    ":hover": {
      background: theme?.palette?.info?.dark
    }
  })
);

const SingleQuote = ({ quote, index, sortBy, showSameSupplier }) => {
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();
  const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (index === 0) return setViewDetails(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    return setViewDetails(false);
  }, [sortBy]);

  const handleViewDetailsToggle = () => {
    // if (!viewDetails) {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    // }
    setViewDetails(!viewDetails);
  };

  return (
    <>
      <GridContainerWrap flexDirection={upSmallScreen ? "row" : "column"} container spacing={0} ref={scrollRef}>
        <GridItemWrap
          item
          xs={upSmallScreen ? 2.5 : 12}
          isMonthlyCost={true}
          upSmallScreen={upSmallScreen}
          columnTitle={true}
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: theme?.palette.secondary.contrastText
          }}
        >
          <Typography
            variant="h6"
            aria-label="Quote Amount"
          >
            £{quote?.monthlyCost?.toFixed(2)}
            <Typography variant="body2" style={{ fontSize: "12px" }}>
              per month (excl VAT)
            </Typography>
          </Typography>
          {!upSmallScreen && (sortBy !== "sameSupplierQuotes") && showSameSupplier && <GridItemWrap bgcolor={"#ffffff !important"} columnTitle={true} flexDirection="row" displayType={"flex"} alignItems="center" xs={12}>
            {quote.sameSupplier ? <DoneIcon/> : <CloseIcon/> }  
            <Typography display="inline" style={{fontSize: "12px", color:"#000000", marginTop: "2px"}} variant="body2">Same Supplier</Typography>
          </GridItemWrap>}
        </GridItemWrap>
        <GridItemWrap
           item
           xs={upSmallScreen ? 2.5 : 12}
           firstItem={true}
           upSmallScreen={upSmallScreen}
           columnTitle={true}
           bgcolor={"#ffffff !important"}
           sx={{
             backgroundColor: "primary.light",
             display: "flex",
             flexDirection: "column",
             justifyContent: "center",
           }}
         >
            <Typography
              variant="h6"
              aria-label="Annualised contract value"
              color="primary.dark"
            >
              £{quote?.annualizedContractValue?.toFixed(2)}
              <Typography variant="body2" style={{ fontSize: "12px" }}>
                Annualised Contract Value
              </Typography>
            </Typography>
          </GridItemWrap>
        {upSmallScreen && (
          <>
            <GridItemWrap columnTitle={false} upSmallScreen={upSmallScreen} item xs={(sortBy !== "sameSupplierQuotes" && showSameSupplier) ? 3 : 5}>
               <Typography
                  variant="body2"
                  aria-label="Excess Weight Charge"
                >
                  {quote?.prices.map((price, i) => {

                  if(price.wasteType !== "WT_GW" || price?.excessWeightCharge <= 0) return null;
                 
                  return (
                    <p
                      key={
                        price?.weightRestrictions + price?.excessWeightCharge + i
                      }
                      style={{ margin: 0 }}
                    >
                      {`${price?.weightRestrictions}kg limit & £${price?.excessWeightCharge?.toFixed(
                        2
                      )} per kg`}
                    </p>
                  );
                })}
                {quote?.prices.some((price) => price.wasteType === "WT_GW") 
                ? <>{quote?.prices.some((price) => price?.excessWeightCharge > 0) 
                    ? <Typography variant="body1" style={{fontSize: "12px", color:"#000000", marginTop: "2px"}}>General Waste excess weight charges</Typography>
                    : <Typography variant="body2" style={{fontSize: "12px", color:"#000000", marginTop: "2px"}}>No general waste excess weight charges</Typography>
                    }</> 
                : null}
                </Typography> 
            </GridItemWrap>
            {(sortBy !== "sameSupplierQuotes" && showSameSupplier) && <GridItemWrap columnTitle={false} upSmallScreen={upSmallScreen} item xs={2}>
              {quote.sameSupplier ? <DoneIcon/> : <CloseIcon/> }
              <Typography style={{fontSize: "12px", color:"#000000", marginTop: "2px"}} variant="body1">Same Supplier</Typography>
            </GridItemWrap>}
            <GridItemWrap
              item
              columnTitle={false}
              xs={upSmallScreen ? 2 : 12}
              sx={{ padding: "5px" }}
              lastItem={true}
              upSmallScreen={upSmallScreen}
            >
              <ToggleButton
                variant="contained"
                size={upSmallScreen ? "medium" : "small"}
                upSmallScreen={upSmallScreen}
                viewDetails={viewDetails}
                theme={theme}
                onClick={() => handleViewDetailsToggle()}
              >
                {viewDetails ? "Hide Details" : "View Details"}
              </ToggleButton>
            </GridItemWrap>
          </>
        )}
      </GridContainerWrap>
      <ViewDetails
        viewDetails={viewDetails}
        handleViewDetailsToggle={handleViewDetailsToggle}
        quote={quote}
      />
      {!upSmallScreen && <Divider sx={{ margin: "15px 0" }} />}
    </>
  );
};

export default SingleQuote;
