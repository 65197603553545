import React from "react";
import { Container, LinearProgress, Card, CardHeader } from "@mui/material";

const GenerateQuotes = () => {

    return (
        <Container sx={{flex:50, alignItems:'center', alignContent:'center', textAlign: 'center' }}>
            <Card sx={{mt:18, mb:3}}>
                <CardHeader
                    title="Generating Quotes"
                    titleTypographyProps={{ fontSize: "20px !important" }}
                    subheader="Please wait while we generate your quotes for you"
                    sx={{color:'primary.main'}} />
            </Card>
            <LinearProgress color="primary" />
        </Container>
    );
};

export default GenerateQuotes;