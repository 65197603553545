import React, { useState } from "react";
import { useTheme } from "@mui/material/styles"; 
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { 
    Container, 
    Box, 
    Card, 
    CardHeader, 
    CardContent, 
    Divider, 
    FormControl, 
    RadioGroup, 
    Radio, 
    Grid, 
    Typography, 
    FormLabel, 
    FormHelperText,
    FormControlLabel, 
    InputAdornment,
    useMediaQuery,
    Select,
    MenuItem,
    InputLabel,
    TextField
} from "@mui/material";

import PageTitle from "../page/pageTitle";
import TextBox from "../form/textbox";
import FormErrors from "../form/formErrors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { CalculatedPrice } from '../../types/quote';
import { LeadQuoteDetails } from "../../api/endpoints/lead";

export interface CreateLeadState {
    businessName: string | null,
    contactTitle: string,
    contactForename: string | null,
    contactSurname: string | null,
    telephoneNumber: string | null,
    emailAddress: string | null,
    happyToBeContacted: boolean | null,
    inContract: boolean | null,
    comments?: string
};

interface CreateLeadErrorState {
    businessName: boolean,
    contactTitle: boolean,
    contactForename: boolean,
    contactSurname: boolean,
    telephoneNumber: boolean,
    emailAddress: boolean,
    happyToBeContacted: boolean,
    contractEndDate: boolean
}

export interface CreateLeadData {
    businessName: string,
    contactTitle: string;
    contactForename: string;
    contactSurname: string;
    telephoneNumber: string;
    emailAddress: string;
    inContract: boolean;
    services: LeadQuoteDetails[];
    quoteReference: string;
    monthlyCost: number;
    annualisedContractValue: number,
    contractEndDate?: string;
    doNotCall: boolean;
    comments?: string; 
}

export interface CreateLeadProps {
    onFormSubmitted: any,
    submittingLead: boolean
}

const initialState:CreateLeadState = {
    businessName: null,
    contactTitle: "",
    contactForename: null,
    contactSurname: null,
    telephoneNumber: null,
    emailAddress: null,
    happyToBeContacted: null,
    inContract: null
}

const initialErrorState:CreateLeadErrorState = {
    businessName: false,
    contactTitle: false,
    contactForename: false,
    contactSurname: false,
    telephoneNumber: false,
    emailAddress: false,
    happyToBeContacted: false,
    contractEndDate: false
}

const getFormErrors = (errorState:CreateLeadErrorState) => {
    
    var errorList:string[] = [];

    if(errorState.businessName) {
        errorList.push("Business Name");
    }

    if(errorState.contactTitle || errorState.contactForename || errorState.contactSurname) {
        errorList.push("Contact");
    }

    if(errorState.telephoneNumber) {
        errorList.push("Telephone Number");
    }

    if(errorState.emailAddress) {
        errorList.push("Email Address");
    }

    if(errorState.happyToBeContacted) {
        errorList.push("Is the customer OK to be contacted?")
    }

    if(errorState.contractEndDate) {
        errorList.push("Date current contract expires")
    }

    return errorList;
}

const CreateLead = (props: CreateLeadProps) => {

    //COMPONENT STATE
    const [leadInformation, setLeadInformation] = useState<CreateLeadState>(initialState);
    const [errorState, setErrorState] = useState<CreateLeadErrorState>(initialErrorState);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const selectedQuote = useSelector(
        (state: RootState) => state.quote.selectedQuote
    );

    //GLOBAL STATE
    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [contractExpirationDate, setContractExpirationDate] =
    useState<Dayjs | null>(null);

    //HANDLERS

    function formatDate() {
        function prependZero(number) {
          if (number >= 10) return number;
          return `0${number}`;
        }
    
        let month = contractExpirationDate?.$M + 1;
        let day = contractExpirationDate?.$D;
    
        return `${contractExpirationDate?.$y}-${prependZero(month)}-${prependZero(
          day
        )}`;
      }
    

    const changeContractExpirationDate = (newDate: Dayjs | null) => 
    {
        setContractExpirationDate(newDate);
    };

    const handleBusinessNameChanged = (value: string) => {
        
        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                businessName: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                businessName: value == null || value == "" ? true : false
            }
        }));
    }

    const handleContactTitleChanged = (value: string) => {

        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                contactTitle: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                contactTitle: value == null || value == "" ? true : false
            }
        }));
    }

    const handleContactForenameChanged = (value: string) => {

        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                contactForename: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                contactForename: value == null || value == "" ? true : false
            }
        }));
    }

    const handleContactSurnameChanged = (value: string) => {

        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                contactSurname: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                contactSurname: value == null || value == "" ? true : false
            }
        }));
    }

    const handleTelephoneNumberChanged = (value: string, valid: boolean) => {

        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                telephoneNumber: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                telephoneNumber: value == null || value == "" || !valid ? true : false
            }
        }));
    }

    const handleEmailAddressChanged = (value: string, valid: boolean) => {

        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                emailAddress: value
            }
        }));

        //Check if valid and set component state
        setErrorState((state) => ({
            ...state,
            ...{
                emailAddress: value == null || value == "" || !valid ? true : false
            }
        }));
    }

    const handleHappyToBeContactedChanged = (value: string) => {
        
        //Set lead information
        setLeadInformation((state) => ({
            ...state,
            ...{
                happyToBeContacted: value == "true"
            }
        }));

        //Set component error state
        setErrorState((state) => ({
            ...state,
            ...{
                happyToBeContacted: false
            }
        }));
    }

    const handleInContractChanged = (value: string) => {
        setLeadInformation((state) => ({
            ...state,
            ...{
                inContract: value == "true"
            }
        }));
    }

    const handleCommentsChanged = (value: string) => {

        setLeadInformation((state) => ({
            ...state,
            ...{
                comments: value
            }
        }));
    }

    const handleSubmitOnClick = () => {

        var formInvalid = false;

        //Check business name
        if(errorState.businessName || leadInformation.businessName == null || leadInformation.businessName == "") {
           
            //Update component state so fields errror shows
            if(!errorState.businessName) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        businessName: true
                    }
                }));
            }

            formInvalid = true;
        }

        //Check contact title
        if(errorState.contactTitle || leadInformation.contactTitle == null || leadInformation.contactTitle == "") {

            //Update component state so fields errror shows
            if(!errorState.contactTitle) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        contactTitle: true 
                    }
                }));
            }
            
            formInvalid = true;
        }

        //Check contact forename
        if(errorState.contactForename || leadInformation.contactForename == null || leadInformation.contactForename == "") {

            //Update component state so fields errror shows
            if(!errorState.contactForename) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        contactForename: true 
                    }
                }));
            }

            formInvalid = true;
        }

        //Check contact surname
        if(errorState.contactSurname || leadInformation.contactSurname == null || leadInformation.contactSurname == "") {

            //Update component state so fields errror shows
            if(!errorState.contactSurname) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        contactSurname: true 
                    }
                }));
            }
            
            formInvalid = true;
        }

        //Check telephone number
        if(errorState.telephoneNumber || leadInformation.telephoneNumber == null || leadInformation.telephoneNumber == "") {

            //Update component state so fields error shows
            if(!errorState.telephoneNumber) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        telephoneNumber: true 
                    }
                }));
            }
            
            formInvalid = true;
        }

        //Check email address 
        if(errorState.emailAddress || leadInformation.emailAddress == null || leadInformation.emailAddress == "") {

            //Update component state so fields error shows
            if(!errorState.emailAddress) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        emailAddress: true 
                    }
                }));
            }
            
            formInvalid = true;
        }

        //Check happy to be contacted
        if(errorState.happyToBeContacted || leadInformation.happyToBeContacted == null) {

            //Update component state so fields error shows
            if(!errorState.happyToBeContacted) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        happyToBeContacted: true
                    }
                }));
            }
            
            formInvalid = true;
        }

        if(errorState.contractEndDate || (leadInformation.inContract && contractExpirationDate === null )) {

            //Update component state so fields error shows
            if(!errorState.contractEndDate) {

                setErrorState((state) => ({
                    ...state,
                    ...{
                        contractEndDate: true
                    }
                }));
            }
            
            formInvalid = true;
        }

        //Callback parent if lead information valid
        if(!formInvalid && selectedQuote !== null) {
            const data: CreateLeadData = {
                services: selectedQuote?.prices.map((item) => {
                    let quoteItemDetails = {
                      supplier_id: item.supplier.id,
                      containerType: item.containerType,
                      wasteType: item.wasteType,
                      qty: item.qty,
                      collectionFrequency: item.collectionFrequency,
                      ...(item?.daysPerWeek && { daysPerWeek: item?.daysPerWeek }),
                      amount: item.amount,
                      margin: item.margin,
                      weightRestrictions: item?.weightRestrictions,
                      excessWeightCharge: item?.excessWeightCharge,
                    };
                    return quoteItemDetails;
                  }),
                  businessName: leadInformation.businessName as string,
                  contactTitle: leadInformation.contactTitle,
                  contactForename: leadInformation.contactForename as string,
                  contactSurname: leadInformation.contactSurname as string,
                  telephoneNumber: leadInformation.telephoneNumber as string,
                  emailAddress: leadInformation.emailAddress as string,
                  doNotCall: leadInformation.happyToBeContacted ? false : true,
                  quoteReference: selectedQuote.quoteReference,
                  monthlyCost: selectedQuote.monthlyCost,
                  annualisedContractValue: selectedQuote.annualizedContractValue,
                  inContract: leadInformation.inContract === null ? false : leadInformation.inContract,
                  ...(leadInformation.inContract && { contractEndDate: formatDate() }),
                  ...(leadInformation.comments && { comments: leadInformation.comments })
            };

            props.onFormSubmitted(data);
        }

        else {
            setShowFormErrors(true);
        }
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
    }

    const componentStyles = {
        submitLead: {
            '&.MuiLoadingButton-loading': {
                backgroundColor: theme.colors.success.dark
             },
             '& .MuiLoadingButton-loadingIndicator': {
                 color:"white",
                 fontWeight:'bold'
             }
        }
    }

    return (
        <Container>
            <Box mb={2}>
                <PageTitle subHeading="Please provide the below information so we have all the required details to generate a lead for the customer" />
            </Box>
            <Card>
                <CardHeader title={ <Typography variant="h6">Contact Details</Typography> } />
                <Divider />
                <CardContent>
                    <Box mb={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextBox 
                                    id="input-business-name"
                                    width={upSmallScreen ? "40%" : "100%"}
                                    showFormLabel={true}
                                    showInputLabel={false}
                                    label="Business Name"
                                    value={leadInformation.businessName}
                                    showError={errorState.businessName}
                                    errorMessage="* Please enter the business name"
                                    onChange={(value:string) => handleBusinessNameChanged(value)}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" mt={2} color="secondary">Contact Name</Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="input-contact-title" error={errorState.contactTitle}>Title</InputLabel> 
                                    <Select 
                                        id="input-contact-title"
                                        value={leadInformation.contactTitle}
                                        error={errorState.contactTitle}
                                        label="Title"
                                        onChange={(event) => handleContactTitleChanged(event.target.value ?? "")}
                                        fullWidth>
                                        <MenuItem value="Mr">Mr</MenuItem>
                                        <MenuItem value="Ms">Ms</MenuItem>
                                        <MenuItem value="Mrs">Mrs</MenuItem>
                                    </Select>
                                    {
                                        errorState.contactTitle &&
                                        <FormHelperText error>
                                            * Title required
                                        </FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextBox 
                                    id="input-contact-forename"
                                    width="100%"
                                    showFormLabel={false}
                                    showInputLabel={true}
                                    label="Forename"
                                    value={leadInformation.contactForename}
                                    showError={errorState.contactForename}
                                    errorMessage="* Forename required"
                                    onChange={(value:string) => handleContactForenameChanged(value)}
                                    />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextBox 
                                    id="input-contact-surname"
                                    width="100%"
                                    showFormLabel={false}
                                    showInputLabel={true}
                                    label="Surname"
                                    value={leadInformation.contactSurname}
                                    showError={errorState.contactSurname}
                                    errorMessage="* Surname required"
                                    onChange={(value:string) => handleContactSurnameChanged(value)}
                                    /> 
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextBox 
                                    id="input-telephone"
                                    width={upSmallScreen ? "40%" : "100%"}
                                    showFormLabel={true}
                                    showInputLabel={false}
                                    label="Telephone Number"
                                    type="tel"
                                    value={leadInformation.telephoneNumber}
                                    showError={errorState.telephoneNumber}
                                    errorMessage="* Please enter a valid telephone number"
                                    startAdornment={<InputAdornment position="start">+44</InputAdornment>}
                                    inputProps={{
                                        minLength:10,
                                        maxLength:11,
                                        pattern:"[1-9][0-9]*"
                                    }}
                                    preventInvalidChars={true}
                                    onChange={(value:string, valid:boolean) => handleTelephoneNumberChanged(value, valid)}
                                    /> 
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextBox 
                                    id="input-email-address"
                                    width={upSmallScreen ? "40%" : "100%"}
                                    showFormLabel={true}
                                    showInputLabel={false}
                                    label="Email Address"
                                    type="email"
                                    value={leadInformation.emailAddress}
                                    showError={errorState.emailAddress}
                                    errorMessage="* Please enter a valid email address"
                                    onChange={(value:string, valid:boolean) => handleEmailAddressChanged(value, valid)}
                                    /> 
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <Box mt={2}>
                <Card>
                    <CardHeader title={ <Typography variant="h6">Other Information</Typography> } />
                    <Divider />
                    <CardContent>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl error={errorState.happyToBeContacted}>
                                        <FormLabel id="input-in-contract-label">Happy to be contacted by WQS?</FormLabel>
                                        <RadioGroup 
                                            row 
                                            aria-labelledby="input-in-contract-label" 
                                            name="input-in-contract"
                                            value={leadInformation.happyToBeContacted}
                                            onChange={(e) => handleHappyToBeContactedChanged(e.target.value)}>
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                        {
                                            errorState.happyToBeContacted &&
                                            <FormHelperText error>
                                                * Please specify if the customer is OK to be contacted or not
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="input-in-contract-label">In contract currently?</FormLabel>
                                        <RadioGroup 
                                            row 
                                            aria-labelledby="input-in-contract-label" 
                                            name="input-in-contract"
                                            value={leadInformation.inContract}
                                            onChange={(e) => handleInContractChanged(e.target.value)}>
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {leadInformation.inContract && (
                                    <Grid item xs={12} mt={2}>
                                        <FormControl>
                                            <FormLabel id="input-in-contract-expires-label">Date current contract expires</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label=""
                                                    inputFormat="YYYY-MM-DD"
                                                    value={contractExpirationDate}
                                                    onChange={changeContractExpirationDate}
                                                    disablePast={true}
                                                    renderInput={(params) => (
                                                    <TextField {...params} fullWidth />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {
                                                errorState.contractEndDate &&
                                                <FormHelperText error>
                                                    * Please specify the contract expiry date
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextBox 
                                        id="input-email-address"
                                        width="100%"
                                        showFormLabel={true}
                                        showInputLabel={false}
                                        label="Additional Comments"
                                        multiline={true}
                                        rows={5}
                                        value={leadInformation.comments}
                                        onChange={(value:string) => handleCommentsChanged(value)}
                                        /> 
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box mt={2} textAlign="center">
                <LoadingButton 
                    variant="contained" 
                    size="large" 
                    color="success"
                    sx={{...componentStyles.submitLead, width: upSmallScreen ? "30%" : "100%" }}
                    loading={props.submittingLead}
                    onClick={handleSubmitOnClick} >
                    Submit Lead
                </LoadingButton>
            </Box>
            <FormErrors
                title="Please resolve the issues with the following fields before submitting."
                errors={getFormErrors(errorState)}
                showErrors={showFormErrors}
                onClose={handleCloseFormErrors}
                />
        </Container>
    )
};

export default CreateLead