import React from "react";
import { Select, MenuItem, SelectChangeEvent, Box, Typography } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";

import StyledSlider from "../../general/styledSlidebar";
import Utils from "../../../utils/utils";

export interface CollectionFrequencyProps {
    selectedCollectionFrequency: string,
    selectedDaysPerWeek: number | null,
    onChange: any,
    onDaysPerWeekChange: any
}

const getCollectionFrequencyName = (cf: string, allCollectionFrequencies:any[]) => {

    var cfName = "";

    allCollectionFrequencies.forEach((item:any) => {

        if(item.apiValue == cf) {
            cfName = item.name;
            return;
        }
    })

    return cfName;
}

const daysPerWeek = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' }
];

const CollectionFrequency = (props:CollectionFrequencyProps) => {

    //Fetch waste data from Contentful
    const data = useStaticQuery(
        graphql`
            query containerDetailsQuery {
                allContentfulCollectionFrequency(sort: {fields: order, order: ASC}) {
                    nodes {
                      name
                      apiValue
                    }
                }
            }`
    );

    const handleCollectionFrequencyChanged = (e:SelectChangeEvent<string>) => {

        //Parent callback
        props.onChange({
            key: e.target.value,
            value: getCollectionFrequencyName(e.target.value, data.allContentfulCollectionFrequency.nodes)
        });
        Utils.scrollToSection("addToQuote", 0);
    }

    const handleDaysPerWeekChanged = (e:Event, value:number) => {

        //Parent callback
        props.onDaysPerWeekChange(value);
        Utils.scrollToSection("addToQuote", 0)
    }

    return (
        <Box>
            <Select 
                sx={{width:"98%", backgroundColor:'white'}} 
                value={props.selectedCollectionFrequency} 
                onChange={ (e:SelectChangeEvent<string>) => handleCollectionFrequencyChanged(e)}>
                {   
                    data.allContentfulCollectionFrequency.nodes.map((cf:any) => (
                        <MenuItem value={cf.apiValue} key={cf.apiValue}>{cf.name}</MenuItem>
                    ))
                }
            </Select>
            <Box mt={1} pt={2} px={1} hidden={props.selectedCollectionFrequency != "CF_WK"}>
                <Typography variant="body1" mt={1} mb={2}>How many days per week would this need to be collected?</Typography>
                <StyledSlider
                    aria-label="Always visible"
                    min={0}
                    max={7}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={daysPerWeek}
                    value={props.selectedDaysPerWeek ?? 0}
                    onChange={(event, value) => handleDaysPerWeekChanged(event, value as number)} />
            </Box>
        </Box>
    )
}

export default CollectionFrequency