
export default class Utils 
{
    public static scrollToSection(elementId: string, offset: number) 
    {
        const element = document.getElementById(elementId);

        if(element !== null) {
            window.scroll({ top: element.offsetTop - offset, behavior: "smooth" });
        }
    }
}