import React, { useState } from "react";
import {
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepIconProps,
  Typography,
  stepConnectorClasses,
  stepLabelClasses,
} from "@mui/material";
import { Person, ViewList, ShoppingCart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import * as Sentry from "@sentry/gatsby";

import Layout from "../layout";
import { RootState } from "../store/store";
import {
  nextStep,
  setStep,
  setGeneratedQuote,
  acceptQuote,
  resetState,
} from "../store/quoteSlice";
import QuoteDetails from "../components/quote/quoteDetails";
import GenerateQuotes from "../components/quote/generateQuotes";
import Quotes, { Quote } from "../components/quote/quotes";
import CreateLead from "../components/lead/create";
import QuoteType from "../components/quote/quoteType";
import { CreateLeadState, CreateLeadData } from '../components/lead/create';
import createLead, {
  LeadDetails,
  LeadQuoteDetails,
} from "../api/endpoints/lead";
import WasteDetails from "../types/wasteDetails";
import FormErrors from "../components/form/formErrors";
import LeadCreatedModal from "../components/quote/LeadCreatedModal";
import generateQuote from "../api/endpoints/quote";
import { QuoteResult, Price, QuoteRequestDetails, QuoteRequest } from "../types/quote";

//Wizard steps
const StepLabelStyled = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.disabled}`]: {
    color: "#ccc",
  },
  [`& .${stepLabelClasses.label}.${stepLabelClasses.completed}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${stepLabelClasses.label}.${stepLabelClasses.active}`]: {
    fontWeight: "bold",
    fontSize: "1.12em",
    color: theme.palette.primary.dark,
    "@media (max-width:600px)": {
      fontSize: "0.94em",
    },
  },
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: 400,
    fontSize: "14px",
    "@media (max-width:600px)": {
      fontSize: "3.5vw",
    },
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Person style={{ width: "36px", height: "36px" }} />,
    2: <ViewList style={{ width: "36px", height: "36px" }} />,
    3: <ShoppingCart style={{ width: "30px", height: "30px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Quote Details", "Quote Comparison", "Create Lead"];

const QuotePage = () => {
  //COMPONENT STATE
  const [submittingLead, setSubmittingLead] = useState(false);
  const [showError, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showCreatedLeadModal, setShowCreatedLeadModal] = useState<boolean>(false);

  //GLOBAL STATE
  const wasteDetails = useSelector(
    (state: RootState) => state.quote.wasteDetails
  );
  const quote = useSelector((state: RootState) => state.quote.generatedQuote);

  const renderStep = (currentStep: number) => {
    switch (currentStep) {
      case -1:
        return <QuoteType></QuoteType>;
      case 0:
        return <QuoteDetails onGetQuote={handleGetQuoteClicked}></QuoteDetails>;
      case 1:
        return <GenerateQuotes></GenerateQuotes>;
      case 2:
        return (
          <Quotes
            quoteReference={quote?.quoteReference ?? ""}
            quotes={formatQuote(wasteDetails, quote)}
            onAcceptPrice={handleAcceptPriceClicked}
            onAmendQuote={handleAmendQuoteClicked}
            onCreateLead={handleCreateLeadClicked}
          ></Quotes>
        );
      case 3:
        return (
          <CreateLead
            submittingLead={submittingLead}
            onFormSubmitted={(leadInfo: CreateLeadData) =>
              handleNewLeadSubmitted(leadInfo)
            }
          ></CreateLead>
        );
      default:
        return <Typography variant="h2">No step selected</Typography>;
    }
  };

  const formatQuote = (
    wasteDetails: WasteDetails[],
    generatedQuote: QuoteResult | null
  ) => {
    const formattedQuote: Quote[] = [];

    wasteDetails.forEach((item: WasteDetails, index) => {
      var prices: Price[] = [];

      //Add corresponding prices to list
      if (generatedQuote != null && generatedQuote?.exactMatches != null) {
        generatedQuote?.exactMatches.forEach((price: Price) => {
          if (
            price.wasteType == item.wasteType.key &&
            price.containerType == item.containerType.key
          ) {
            prices.push(price);
          }
        });

        //Set waste details
        formattedQuote.push({
          wasteType: item.wasteType.value,
          containerType: item.containerType.value,
          collectionFrequency: item.collectionFrequency?.value ?? "",
          qty: item.qty ?? 0,
          prices: prices.sort(function (a, b) {
            if (a.amount < b.amount) {
              return -1;
            }

            return 0;
          }),
        });
      }
    });

    return formattedQuote;
  };

  //GLOBAL STATE

  const currentStep = useSelector(
    (state: RootState) => state.quote.currentStep
  );
  const quoteType = useSelector((state: RootState) => state.quote.quoteType);
  const selectedAddress = useSelector(
    (state: RootState) => state.quote.address
  );
  const selectedQuoteDetails = useSelector(
    (state: RootState) => state.quote.wasteDetails
  );
  const generatedQuote = useSelector(
    (state: RootState) => state.quote.generatedQuote
  );
  const { user, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  //HANDLERS

  React.useEffect(() => {
    //If refresh reset state
    if (location.search != null && location.search.includes("refresh=true")) {
      //Reset global state
      dispatch(resetState());

      //Remove reset from path
      window.location.replace(window.location.pathname);
    }
  });

  const handleGetQuoteClicked = async () => {
    //Progress to next step and show loader
    dispatch(nextStep());

    //Construct body of http request
    var requestDetails: QuoteRequestDetails[] = [];

    selectedQuoteDetails.forEach((qd: WasteDetails) => {
      requestDetails.push({
        containerType: qd.containerType.key,
        wasteType: qd.wasteType.key,
        qty: qd.qty as number,
        collectionFrequency: qd.collectionFrequency?.key as string,
        daysPerWeek: qd.daysPerWeek,
      });
    });

    var data: QuoteRequest = {
      address: {
        postcode: selectedAddress?.postcode as string,
        postcodeOutward: selectedAddress?.postcodeOutward as string,
        addressLine1: selectedAddress?.addressLine1,
        addressLine2: selectedAddress?.addressLine2,
        addressLine3: selectedAddress?.addressLine3,
        postTown: selectedAddress?.postTown,
        udprn: selectedAddress?.udprn,
        uprn: selectedAddress?.uprn,
      },
      organizationName: selectedAddress?.organizationName,
      username: user?.email as string,
      organizationCode: user?.org_id,
      details: requestDetails,
      utm: {
        source: user?.org_id,
        medium: "Sales Portal"
      }
    };

    //Retrieve access token to make API call
    const token = await getAccessTokenSilently();

    //Generate quote
    generateQuote(data, token)
      .then((res) => {
        //Store quote
        dispatch(setGeneratedQuote(res.data));

        //Move to next step
        dispatch(nextStep());
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((err) => {
        Sentry.configureScope(function (scope) {
          scope.setTag("email", user?.email);
        });
        Sentry.captureException(err);

        setShowErrors(true);
        setErrorMessage(
          "Unfortunately an error has occured please try again later."
        );
      });
  };

  const handleAcceptPriceClicked = (
    wasteType: string,
    containerType: string,
    id: number
  ) => {
    //Log accepted prices
    //dispatch(acceptQuote({ wasteType, containerType, id }));
  };

  const handleAmendQuoteClicked = () => {
    //Remove stored quote
    //dispatch(setGeneratedQuote(null));

    //Go back to quote details stage
    //dispatch(setStep(0));

    //TEMP: Until amend quote done
    dispatch(resetState());
  };

  const handleCreateLeadClicked = () => {
    //Move to next step
    dispatch(nextStep());

    //Reset window position
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNewLeadSubmitted = async (leadInfo: CreateLeadData) => {
    //Show loader to indicate lead being submitted
    setSubmittingLead(true);

    //Construct body of http request
    var quote: LeadQuoteDetails[] = [];

    var data: LeadDetails = {
      salutation: leadInfo.contactTitle as string,
      firstName: leadInfo.contactForename as string,
      lastName: leadInfo.contactSurname as string,
      businessName: leadInfo.businessName as string,
      telephoneNumber: ("+44" + leadInfo.telephoneNumber) as string,
      leadSource: "Affiliate",
      organizationCode: user?.org_id,
      inContract: leadInfo.inContract,
      quoteReference: leadInfo.quoteReference,
      comments: leadInfo.comments ?? "",
      username: user?.email as string,
      doNotCall: leadInfo.doNotCall,
      email: leadInfo.emailAddress,
      address: {
          ...((selectedAddress?.uprn !== undefined && selectedAddress?.uprn !== null && selectedAddress.uprn !== "") && { uprn: parseInt(selectedAddress.uprn) }),
          addressLine1: selectedAddress?.addressLine1 as string,
          addressLine2: selectedAddress?.addressLine2 as string,
          addressLine3: selectedAddress?.addressLine3 as string,
          postcode: selectedAddress?.postcode as string,
      },
      annualCommissionValue: leadInfo.annualisedContractValue,
      monthlyCost: leadInfo.monthlyCost,
      services: leadInfo.services,
      ...(leadInfo.inContract && { contractEndDate: leadInfo.contractEndDate }),
    };

    //Retrieve access token to make API call
    const token = await getAccessTokenSilently();

    //Create lead
    createLead(data, token)
      .then(() => {
        //Set submission as finished
        setSubmittingLead(false);

        setShowCreatedLeadModal(true);
      })
      .catch((err) => {
        Sentry.configureScope(function (scope) {
          scope.setTag("email", user?.email);
        });
        Sentry.captureException(err);

        //Show error
        setShowErrors(true);
        setErrorMessage(
          "Unfortunately an error has occured please try again later."
        );

        //Set submission as finished
        setSubmittingLead(false);
      });
  };

  const handleCloseError = () => {
    setShowErrors(false);
    dispatch(resetState());
  };

  return (
    <Layout>
      <Box hidden={currentStep < 0}>
        <Stepper
          alternativeLabel
          activeStep={currentStep == 0 ? currentStep : currentStep - 1}
          connector={<ColorlibConnector />}
          sx={{ backgroundSize: "cover", backgroundColor: "transparent" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabelStyled StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabelStyled>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Container maxWidth="lg" sx={{ mt: 1, mb: 3 }}>
        {currentStep != -1 && currentStep != 1 && currentStep != 2 && currentStep != 3 && (
          <Typography variant="h3" color="primary.light" ml={3}>
            {quoteType?.value} Quote
          </Typography>
        )}
        {currentStep == 3 && (
          <Typography variant="h3" color="primary.light" ml={3}>
            Create Lead
          </Typography>
        )}
        <Box>{renderStep(currentStep)}</Box>
      </Container>
      <FormErrors
        title={errorMessage}
        showErrors={showError}
        onClose={() => handleCloseError()}
      />
      <LeadCreatedModal open={showCreatedLeadModal}/>
    </Layout>
  );
};

export default QuotePage;
