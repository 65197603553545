import { Box, Button, Divider, Drawer, Grid, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../../store/quoteSlice";
import { WeightRestriction } from "../ComparedQuotes";
import CheckboxFilter from './CheckboxFilter';

export interface IQuoteFilters {
    weight: QuoteFilter
}

export interface QuoteFilter {
    value: string | number | string[] | number[];
}

interface QuoteFilterProps {
    updateFilter: (filter: Partial<IQuoteFilters>) => void;
    filters: IQuoteFilters;
    weightRestrictions: WeightRestriction[];
}

const QuoteFilters = (props: QuoteFilterProps) => {

    const { filters, updateFilter, weightRestrictions } = props;
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleResetFilters = () => {
      dispatch(resetFilter())  
    };

    const drawerWidth: number = 200;

    const weightOptions:  {[key: string]: string} = {};

    weightRestrictions.map((wr) => {
        weightOptions[wr.displayName] = wr.displayName;
    });

    const filterComponents = (<>
        <Typography color="primary.dark" variant="body2" sx={{ fontSize: 17 }}>
            Filter Results
        </Typography>
        <Divider sx={{margin: "15px 0"}}/>
        <CheckboxFilter
            options={weightOptions}
            title={"Excess Weight"}
            setOption={updateFilter}
            selectedOptions={filters.weight.value as string[]}
            propertyName={"weight"}
        />
    </>);

    return (
        <>
            <Button onClick={handleDrawerToggle} variant="contained" color="secondary" sx={{ display: { xs: 'block', xl: 'none', marginBottom: "15px" } }}>Filters</Button>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth
                 }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {<Drawer
                    // container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                       keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', xl: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: "absolute", padding: "15px", backgroundColor: theme.palette.background.default },
                    }}
                >
                    <Grid>{filterComponents}</Grid>
                    <Grid marginTop={"40px"} flexDirection={"column"}>
                        <Button sx={{marginBottom: "10px"}} fullWidth={true} onClick={handleResetFilters} variant="outlined" color="secondary">Clear</Button>
                        <Button fullWidth={true} onClick={handleDrawerToggle} variant="contained" color="success">Show Results</Button>
                    </Grid>
                </Drawer>}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', xl: 'block', backgroundColor: theme.palette.background.default },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: "absolute", padding: "15px", backgroundColor: theme.palette.background.default, top: "90px", left: "100px", zIndex: "0 !important" },
                    }}
                    open
                >
                    <Grid>{filterComponents}</Grid>
                    <Grid marginTop={"40px"} flexDirection={"column"}>
                        <Button sx={{marginBottom: "10px"}} fullWidth={true} onClick={handleResetFilters} variant="outlined" color="secondary">Clear</Button>
                    </Grid>
                </Drawer>
            </Box>
        </>
    )
}

export default QuoteFilters;