import React from "react";
import { Card, CardHeader, CardContent, Grid, CardActions, Button, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { KeyValuePair } from "../../../types/wasteDetails";
import WasteContainer from "../../../types/wasteContainer";

export interface ContainerDetailsProps {
    containerDetails: WasteContainer,
    selectedContainerSize: string,
    onSelected: any
}

const ContainerDetails = (props: ContainerDetailsProps) => {

    //PROPS

    const cardImageData = getImage(props.containerDetails.image);

    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    //HANDLES

    const handleContainerSelected = () => {

        var args:KeyValuePair = {
            key: props.containerDetails.apiValue,
            value: props.containerDetails.title
        };

        props.onSelected(args);
    }

    return (
        <Card sx={{...componentStyles.card}} elevation={4}>
            <CardHeader
                title={ <Typography sx={{ textAlign: 'center', color: 'primary.dark', fontWeight:600 }}>{ props.containerDetails.title } </Typography> }
                subheader={props.containerDetails.description}
                subheaderTypographyProps={{align:'center'}}
                sx={{height:90, lineHeight:90}}
            />
            <CardContent sx={{height: upSmallScreen ? 170 : 165}}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{...componentStyles.centerCell}}>
                        {
                            props.containerDetails.image != undefined && props.containerDetails.image != null &&
                            <Box sx={{margin:'auto', textAlign:"center"}}>
                                <GatsbyImage alt="Container Type" image={cardImageData as IGatsbyImageData} style={{ width: upSmallScreen ? 110 : 120 }} />
                            </Box>
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{backgroundColor:'#f2f2f2', py:2 }}>
                <Button
                    variant={ props.selectedContainerSize == props.containerDetails.apiValue ? "contained" : "outlined"} 
                    fullWidth 
                    onClick={() => handleContainerSelected()}>Select</Button>
            </CardActions>
        </Card>
    );
};

const componentStyles = {
    card: {
        border:'1px solid #F2F2F2',
        '&:hover': {
            cursor:'pointer',
            "& .MuiCardHeader-title": {
                color: 'primary.main'
            },
            '& .cardAvatar': {
                borderColor:'primary.main'
            },
            '& .ctIcon': {
                color:'primary.main'
            }
        },
        '& .ctIcon': {
            height: '50px',
            width: '50px',
            color:'primary.dark'
        }
    },
    centerCell: {
        alignItems:"center", 
        justifyContent:"center", 
        margin:'auto'
    }
}

export default ContainerDetails;