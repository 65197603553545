import { Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { navigate } from "gatsby";
import React from 'react';
import { useDispatch } from "react-redux";
import { resetState } from "../../store/quoteSlice";

interface LeadCreatedModalProps 
{
    open: boolean;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 450,
    backgroundColor: 'background.paper',
    boxShadow: 24,
};

const LeadCreatedModal = (props: LeadCreatedModalProps) => 
{
    const { open } = props;

    const dispatch = useDispatch();

    const handleViewLeads = () => {
        dispatch(resetState());
        navigate("/viewleads");
    }

    return (
        <Modal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card sx={style}>
                    <CardHeader sx={{ backgroundColor: "primary.main", color:"#ffffff" }} title="Lead Received"></CardHeader>
                    <Divider />
                    <CardContent sx={{ p: 4, textAlign: "center" }}>
                        <Typography sx={{paddingBottom: 4, fontWeight: "bold", fontSize: "16px" }} color="primary" variant="body1">The Lead has been received and will appear on the view list in a few minutes.</Typography>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ width: "100%" }}
                            onClick={handleViewLeads}
                        >
                            View Leads
                        </Button>
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )
}



export default LeadCreatedModal;