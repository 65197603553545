import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleQuote from "./SingleQuote";

import QuoteFilters from "./QuoteFilters";
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { IQuoteFilters } from './QuoteFilters/index';
import { RootState } from "../../store/store";
import { cloneDeep } from 'lodash'
import { updateFilter } from "../../store/quoteSlice";
import { QuoteResult, CalculatedPrice } from '../../types/quote';
import { useStaticQuery, graphql } from "gatsby";
import CheapestAndSameSupplier from "./CheapestAndSameSupplier";

export interface WeightRestriction {
  displayName: string;
  lowerWeight: number;
  upperWeight: number;
}

function ComparedQuotes() {
  const dispatch = useDispatch();
  let generatedQuote = cloneDeep<QuoteResult | null>(useSelector((state: RootState) => state.quote.generatedQuote));
  const selectedQuoteDetails = useSelector((state: RootState) => state.quote.wasteDetails);
  const filters = useSelector((state: RootState) => state.quote.filters)
  const resultLimit: number = 10;
  let bestPriceQuotes: CalculatedPrice[] = [];
  let sameSupplierQuotes: CalculatedPrice[] = [];

  if (generatedQuote === null || generatedQuote.quotes === null) return null;

  for (const quote of generatedQuote.quotes) {
    if (bestPriceQuotes.length < resultLimit)
      bestPriceQuotes.push(quote);

    if (quote.sameSupplier && sameSupplierQuotes.length < resultLimit)
      sameSupplierQuotes.push(quote);
  }
  
  const showSameSupplier = selectedQuoteDetails.length > 1;
  const [sortBy, setSortBy] = useState((showSameSupplier && sameSupplierQuotes.length > 0) ? "sameSupplierQuotes" : "bestPriceQuotes");
  const activeQuotes = (sortBy === "sameSupplierQuotes") ? sameSupplierQuotes : bestPriceQuotes;

  const data = useStaticQuery(
    graphql`
        query weightRestrictionQuery {
            allContentfulWeightRestriction {
                nodes {
                  lowerWeight
                  upperWeight
                  displayName
                }
            }
        }`
  );

  const weightRestrictions: WeightRestriction[] = data.allContentfulWeightRestriction.nodes;


  const handleUpdatFilters = (filter: Partial<IQuoteFilters>) => {
    dispatch(updateFilter(filter));
  }


  if (generatedQuote !== null) {
    for (const key in filters) {
      const filterValue = filters[key];

      if (filterValue.value === "" || filterValue.value.length === 0) continue;

      switch (key) {
        case "weight":
          {
            bestPriceQuotes = bestPriceQuotes.filter((bp) => bp.prices.some(
              (p) => {
                for (const val of filterValue.value) {
                  const restriction: WeightRestriction | undefined = weightRestrictions.find((wr) => wr.displayName === val);
                  if (restriction !== undefined && p.weightRestrictions >= restriction.lowerWeight && p.weightRestrictions <= restriction.upperWeight) return true;
                }
                return false;
              }
            ));

            sameSupplierQuotes = sameSupplierQuotes.filter((bp) => bp.prices.some(
              (p) => {
                for (const val of filterValue.value) {
                  const restriction: WeightRestriction | undefined = weightRestrictions.find((wr) => wr.displayName === val);
                  if (restriction !== undefined && p.weightRestrictions >= restriction.lowerWeight && p.weightRestrictions <= restriction.upperWeight) return true;
                }
                return false;
              }
            ));
          }
          break;
        default:
          break;
      }
    }
  }

  const quotesAvailable: boolean = bestPriceQuotes.length > 0;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems={"center"}>
        <QuoteFilters weightRestrictions={weightRestrictions} filters={filters} updateFilter={handleUpdatFilters} />
        <Grid xs={12}>
          { 
            quotesAvailable && 
            <CheapestAndSameSupplier
              cheapest={bestPriceQuotes[0]?.monthlyCost}
              sameSupplier={sameSupplierQuotes[0]?.monthlyCost}
              sortBy={sortBy}
              showSameSupplier={showSameSupplier}
              setSortBy={setSortBy}
              sameSupplierQuotes={sameSupplierQuotes} />
          }
        {(sameSupplierQuotes.length === 0 && sortBy === "sameSupplierQuotes") ? <Card sx={{padding: "50px 0"}}><Typography textAlign="center">No same supplier quotes found.</Typography></Card> : null}
          {quotesAvailable && activeQuotes.map((quote, i) => {
            return (
              <SingleQuote
                key={`${i}_${quote.quoteReference}`}
                index={i}
                quote={quote}
                sortBy={sortBy}
                showSameSupplier={showSameSupplier}
              />
            );
          })}
          {!quotesAvailable && <Card>
            <CardContent>
              <Typography textAlign={"center"} variant="h6" my={3}>
                No quotes match the currently selected filters.
              </Typography>
            </CardContent>
          </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default ComparedQuotes;