import React from "react";
import { Box, CardContent, Card, Grid, Typography, IconButton, Tooltip, CardHeader, Avatar } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import WasteDetails from "../../types/wasteDetails";

export interface SelectedQuoteItemsProps {
    onAddQuoteItemClick: any,
    onRemoveQuoteItemClick: any
}

const SelectedQuoteItems= (props:SelectedQuoteItemsProps) => {

    //GLOBAL STATE
    
    const selectedWasteDetails = useSelector((state:RootState) => state.quote.wasteDetails);

    //HANDLERS
    
    const handleAddQuoteItemClick = () => {

        //Parent callback
        props.onAddQuoteItemClick();
    }

    const handleRemoveQuoteItemClick = (wasteType:string, containerType:string) => {

        //Parent callback
        props.onRemoveQuoteItemClick(wasteType, containerType);
    }

    return (
        <Box>
            <Grid container spacing={2}>
                {
                    selectedWasteDetails.map((item:WasteDetails, index:number) => (
                        <Grid item xs={12} md={3} key={index}>
                            <Card>
                                <CardHeader 
                                    title={ <Typography variant="body2">Quote item {(index + 1)}</Typography> }
                                    sx={{backgroundColor:'primary.light', color:'white'}}
                                    action={
                                        <Tooltip title="Remove from quote">
                                            <IconButton 
                                                sx={{padding:0, ":hover": { background:"transparent" }}}
                                                onClick={() => handleRemoveQuoteItemClick(item.wasteType.key, item.containerType.key)}>
                                                <Avatar sx={{
                                                    backgroundColor:'white', 
                                                    color:"red", 
                                                    cursor:"pointer", 
                                                    width:28,
                                                    height:28,
                                                    ":hover": { color:"primary.main" }}}>
                                                    <DeleteIcon fontSize="small" />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    />
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Waste Type</Typography>
                                            <Typography variant="body1">{item.wasteType.value}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Container Type</Typography>
                                            <Typography variant="body1">{item.containerType.value}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Quantity</Typography>
                                            <Typography variant="body1">{item.qty}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Frequency</Typography>
                                            <Typography variant="body1">{item.collectionFrequency?.value}</Typography>
                                        </Grid>
                                        <Grid item xs={12} hidden={item.daysPerWeek == null}>
                                            <Typography variant="body2">Days Per Week:</Typography>
                                            <Typography variant="body1">{item.daysPerWeek}</Typography>
                                        </Grid>
                                        <Grid item xs={12} hidden={item.daysPerWeek != null}>
                                            <Box height="41px"></Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={3}>
                    <Card sx={{height:"100%", textAlign:"center"}}>
                        <CardContent sx={{height:"100%", verticalAlign:"middle", margin:"auto", alignContent:"center", alignItems: "middle"}}>
                            <IconButton 
                                color="primary" 
                                aria-label="add" 
                                size="large"
                                onClick={() => handleAddQuoteItemClick() }>
                                <AddCircleOutlineIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body2">Add more services</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SelectedQuoteItems