import apiClient from "../apiClient";
import Address from "../../types/address";

//TYPE DEFINITIONS

export interface findAddressResponse {
    exactMatch?: boolean,
    code: string,
    message: string,
    addresses: Address[]
}

//METHODS

const findAddress = (postcode:string, buildingNumberOrName:string, username:string, organizationCode:string, accessToken: string) => {

    return apiClient.get(
        "/address/find?postcode=" + postcode.replace(' ', '') + "&buildingNameOrNumber=" + buildingNumberOrName + "&organizationCode=" + organizationCode + "&username=" + username, 
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
    );
}

export default findAddress