import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, CardHeader, Grid, Card, CardContent, Typography, CardActions, Button, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useDispatch } from "react-redux";

import PageTitle from "../page/pageTitle";
import { setQuoteType, nextStep } from "../../store/quoteSlice";
import { KeyValuePair } from "../../types/wasteDetails";

interface containerCategoryQuery {
    allContentfulWasteContainerCategory: {
        nodes: [{
            id: string,
            name: string,
            description: string,
            image: {
                gatsbyImageData: IGatsbyImageData
            }
        }]
    }
}

const QuoteType = () => {

    //GLOBAL STATE

    const dispatch = useDispatch();

    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    //HANDLERS

    const handleSelectQuoteTypeClick = (value: KeyValuePair) => {

        //Set quote type in global state
        dispatch(setQuoteType(value))

        //Update globl state to move to the next step
        dispatch(nextStep());
    }

    const data = useStaticQuery<containerCategoryQuery>(
        graphql`
            query containerCategoryQuery {
                allContentfulWasteContainerCategory(sort: {order: ASC, fields: priority}) {
                    nodes {
                        id
                        name
                        description
                        image {
                            gatsbyImageData
                        }
                    }
                }
            }`
    );

    return (
        <Box>
            <Box mt={upSmallScreen ? 6 : 1} mb={3}>
                <PageTitle
                    heading="Quote Type"
                    subHeading="Please select the type of service you'd like a quote for" />
            </Box>
            <Grid container spacing={2}>
                {
                    data.allContentfulWasteContainerCategory.nodes.map((item, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card style={{display: "flex", flexDirection: "column", height:"100%"}}>
                                <CardHeader
                                    title={item.name}
                                    titleTypographyProps={{ fontSize: '16px !important' }}
                                    sx={{backgroundColor:'primary.light', color:'white', textAlign:'center', minHeight: 60 }} />
                                <CardContent>
                                    <Box height={190} paddingBottom={"10px"}>
                                        <GatsbyImage objectFit="contain" image={item.image?.gatsbyImageData} alt="waste image" style={{verticalAlign:"middle", maxHeight: "100%"}} />
                                    </Box>
                                    <Divider></Divider>
                                    <Box mt={2}>
                                        <Typography variant="body1">{item.description}</Typography>
                                    </Box>
                                </CardContent>
                                <CardActions style={{marginTop:"auto", paddingBottom:20}}>
                                    <Button 
                                        variant="contained" 
                                        fullWidth
                                        onClick={() => handleSelectQuoteTypeClick({ key: item.id, value: item.name })}>Get Quote</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

export default QuoteType