import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import StyledSlider from "../../general/styledSlidebar";
import { Mark } from "@mui/base";

export interface ContainerQuantityProps {
    quantities: Mark[],
    maxQty: number,
    selectedQuantity: number,
    onChange: any
}

const ContainerQuantity = (props:ContainerQuantityProps) => {

    //COMPONENT STATE

    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const handleQuantityChanged = (e: Event, value: number | number[]) => {

        //Convert value to number
        const numValue = Number(value);

        //Parent callback
        props.onChange(numValue);
    }

    return (
        <Box pt={ upSmallScreen ? 1 : 4 } pb={1} px={1}>
            <StyledSlider
                aria-label="Always visible"
                min={0}
                max={props.maxQty}
                step={null}
                valueLabelDisplay={ upSmallScreen ? "auto" : "on"}
                marks={props.quantities}
                value={props.selectedQuantity}
                onChange={(event, value) => handleQuantityChanged(event, value)} />
        </Box>
    )
}

export default ContainerQuantity