import React, { ReactNode, FocusEvent, ChangeEvent } from "react";
import { FormControl, FormLabel, InputLabel, OutlinedInput, FormHelperText, InputBaseComponentProps } from "@mui/material";

export interface TextBoxProps {
    id: string,
    width: string | number,
    showFormLabel: boolean,
    showInputLabel: boolean,
    label: string,
    multiline?: boolean,
    rows?: number,
    value?: string | null,
    type?: string,
    showError?: boolean,
    errorMessage?: string,
    startAdornment?: ReactNode,
    inputProps?: InputBaseComponentProps,
    preventInvalidChars?: boolean,
    onChange?: any
}

const TextBox = (props:TextBoxProps) => {

    const handleTextBoxChanged = (event:ChangeEvent<HTMLInputElement>) => {

        //If component set to prevent invalid characters being entered, check validity and if fails don't update
        if(props.preventInvalidChars == true && event.target.validity.patternMismatch) {
            return;
        }

        //Update value
        if(props.onChange != undefined) {
            props.onChange(event.target.value, event.target.validity.valid);
        }
    }

    const handleOnBlurChanged = (event: FocusEvent<any>) => {

        if(props.onChange != undefined) {
            props.onChange(props.value == null || props.value == undefined ? "" : event.target.value, event.target.validity.valid);
        }
    }

    return (
        <FormControl sx={{width:props.width ?? "100%"}}>
            {
                props.showFormLabel &&
                <FormLabel htmlFor={props.id} error={props.showError}>
                    {props.label}
                </FormLabel>
            }
            {
                props.showInputLabel &&
                <InputLabel htmlFor={props.id} error={props.showError}>
                    {props.label}
                </InputLabel>
            }
            <OutlinedInput
                id={props.id}
                value={props.value ?? ""}
                label={props.showInputLabel ? props.label : null}
                multiline={props.multiline}
                rows={props.rows}
                type={props.type}
                error={props.showError}
                inputProps={props.inputProps}
                startAdornment={props.startAdornment}
                onChange={handleTextBoxChanged}
                onBlur={(ev) => handleOnBlurChanged(ev)}
                />
            {
                props.showError &&
                <FormHelperText error>
                    {props.errorMessage}
                </FormHelperText>
            }
        </FormControl> 
    )
}

export default TextBox