import React from "react";
import { Typography, Grid, Box, useMediaQuery } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

const GridContainerWrap = styled(Grid)(({ isfilter }) => ({
  marginBottom: "0",
  height: isfilter ? "100px" : "60px",
  maxHeight: "100px",
}));

const GridItemWrap = styled(Grid)(
  ({ firstitem, lastitem, columntitle, ismonthlycost, isactive }) => {
    const theme = useTheme();
    const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return {
      height: "60px",
      border: `1px solid lightgrey`,
      borderLeft: upSmallScreen && !firstitem && "0px",
      borderRadius: firstitem ? "2px 0 0 2px" : lastitem ? "0 2px 2px 0" : "0",
      display: "grid",
      alignContent: "center",
      justifyItems: "center",
      justifyContent: columntitle && "center",
      textAlign: "center",
      background:
        columntitle || ismonthlycost
          ? theme?.palette.primary.light
          : isactive
            ? theme?.palette.primary.dark
            : "white",
      color:
        columntitle || ismonthlycost
          ? theme?.palette.primary.dark
          : isactive
            ? theme?.palette.secondary.contrastText
            : theme?.palette.primary.dark,
    };
  }
);

const CheapestAndSameSupplier = ({
  cheapest,
  sameSupplier,
  sortBy,
  setSortBy,
  showSameSupplier,
  sameSupplierQuotes
}) => {
  const theme = useTheme();
  const upSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridContainerWrap container spacing={0} isfilter="true">
      { showSameSupplier && (sameSupplierQuotes.length > 0 ?
          (<GridItemWrap
            item
            xs={6}
            sx={{
              cursor: "pointer",
              padding: "40px 0",
            }}
            onClick={() => setSortBy("sameSupplierQuotes")}
            isactive={sortBy === "sameSupplierQuotes"}
            firstitem="true"
          >
            <Typography
              variant="body1"
              aria-label="Same Supplier Title"
              sx={{ whiteSpace: "nowrap" }}
            >
              Same Supplier
            </Typography>
            <Typography
              variant="h6"
              aria-label="Same Supplier price"
            >
              £{sameSupplier?.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              per month (excl VAT)
            </Typography>
          </GridItemWrap>)
          : (
            <GridItemWrap
            item
            xs={6}
            sx={{
              cursor: "pointer",
              padding: "40px 0",
            }}
            onClick={() => setSortBy("sameSupplierQuotes")}
            isactive={sortBy === "sameSupplierQuotes"}
            lastitem="true"
          >
            <Typography
              variant="h6"
              aria-label="No same supplier quotes"
              sx={{ lineHeight: "1.3"}}
            >
              No same supplier quotes found
            </Typography>
          </GridItemWrap>
          ))
        }
        <GridItemWrap
          item
          xs={showSameSupplier ? 6 : 12}
          sx={{
            cursor: "pointer",
            padding: "40px 0",
          }}
          onClick={() => setSortBy("bestPriceQuotes")}
          isactive={sortBy === "bestPriceQuotes"}
          lastitem="true"
        >
          <Typography
            variant="body1"
            aria-label="Cheapest Title"
            sx={{ whiteSpace: "nowrap" }}
          >
            Best Price
          </Typography>
          <Typography
            variant="h6"
            aria-label="Best price"
          >
            £{cheapest?.toFixed(2)}
          </Typography>
          <Typography variant="body1">
            per month (excl VAT)
          </Typography>
        </GridItemWrap>
      </GridContainerWrap>
    </Box>
  );
};

export default CheapestAndSameSupplier;
